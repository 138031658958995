export const FORMULARIO_PROYECTO = 'FORMULARIO_PROYECTO';
export const VALIDAR_FORMULARIO = 'VALIDAR_FORMULARIO';

//Alertas
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

//Csv
export const CSV_HOUSES = 'CSV_HOUSES';
export const ADDITIONAL_HOUSING = 'ADDITIONAL_HOUSING';
export const CSV_PARTICIPANTS_INMUNE = 'CSV_PARTICIPANTS_INMUNE';
export const CSV_HEALTH_POSTS = 'CSV_HEALTH_POSTS';
export const CSV_CLEAN = 'CSV_CLEAN';
export const UPDATE_HOUSES = 'UPDATE_HOUSES';


//Login
export const LOGIN_INTO = 'LOGIN_INTO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_END = 'LOGIN_END';
export const BTN_DENUNCIATION = 'BTN_DENUNCIATION';
export const BTN_INSPECTION_PASIVE = 'BTN_INSPECTION_PASIVE';
export const BTN_INSPECTION_ACTIVE = 'BTN_INSPECTION_ACTIVE';
export const BTN_SPRAYED = 'BTN_SPRAYED';
export const BTN_RETURN = 'BTN_RETURN';

//Denuncia
export const GET_DENUNCIATIONS = 'GET_DENUNCIATIONS';
export const ADD_DENUNCIATION = 'ADD_DENUNCIATION';
export const EDIT_DENUNCIATION = 'EDIT_DENUNCIATION';
export const UPDATE_DENUNCIATION = 'UPDATE_DENUNCIATION';
export const DISABLE_EDIT_DENUNCIATION = 'DISABLE_EDIT_DENUNCIATION';
export const BTN_ADD_DENUNCIATION = 'BTN_ADD_DENUNCIATION';
export const BTN_EDIT_DENUNCIATION = 'BTN_EDIT_DENUNCIATION';
export const CLEAN_BTN_DENUNCIATION = 'CLEAN_BTN_DENUNCIATION';

//Inspeccion
export const GET_INSPECTIONS = 'GET_INSPECTIONS';
export const ADD_INSPECTION = 'ADD_INSPECTION';
export const ADD_INSPECTION_OFFLINE = 'ADD_INSPECTION_OFFLINE';
export const STORE_INSPECTION = 'STORE_INSPECTION';
export const BTN_INSPECTION = 'BTN_INSPECTION';
export const BTN_ROC = "BTN_ROC";
export const CLEAN_INSPECTIONS_OFFLINE = 'CLEAN_INSPECTIONS_OFFLINE';
export const UPDATE_INSPECTIONS = 'UPDATE_INSPECTIONS';

//Cimex
export const ADD_CIMEX = 'ADD_CIMEX';

//Snipper
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

//Rociados
//export const BTN_ROC = "BTN_ROC";
export const BTN_COLINDANTE = "BTN_COLINDANTE";
export const BTN_NO_COLINDANTE = "BTN_NO_COLINDANTE";
export const DISABLED_BTN_COLINDANTE = "DISABLED_BTN_COLINDANTE";
export const DISABLED_BTN_NO_COLINDANTE = "DISABLED_BTN_NO_COLINDANTE";
export const BTN_ADD_ROCIADO = "BTN_ADD_ROCIADO";
export const DISABLED_BTN_ADD_ROCIADO = "DISABLED_BTN_ADD_ROCIADO";
export const ADD_ROCIADO = "ADD_ROCIADO";
export const ADD_SPRAY_OFFLINE = "ADD_SPRAY_OFFLINE";
export const GET_ROCIADOS = "GET_ROCIADOS";
export const BTN_NO_ROCIADO = "BTN_NO_ROCIADO";
export const DISABLED_BTN_NO_ROCIADO = "DISABLED_BTN_NO_ROCIADO";
export const CLEAN_SPRAYS_OFFLINE = 'CLEAN_SPRAYS_OFFLINE';

//Publicidades
export const GET_PUBLICITIES = "GET_PUBLICITIES";
export const ADD_PUBLICITY_OFFLINE = "ADD_PUBLICITY_OFFLINE";
export const CLEAN_PUBLICITIES_OFFLINE = 'CLEAN_PUBLICITIES_OFFLINE';
//Agentes
export const GET_AGENTS = "GET_AGENTS";
export const ADD_AGENT_OFFLINE = "ADD_AGENT_OFFLINE";
export const CLEAN_AGENTS_OFFLINE = 'CLEAN_AGENTS_OFFLINE';

//Rociados Programados
export const GET_ROCIADOS_PROGRAMADOS = 'GET_ROCIADOS_PROGRAMADOS';
export const ADD_ROCIADO_PROGRAMADO = "ADD_ROCIADO_PROGRAMADO";