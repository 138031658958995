import React from "react";

import Score from './Score';
import SignOut from '../../components/Login/SignOut';

const Header = () => {
    return(
        <header>
            <Score/>
            <SignOut/>
        </header>
    );
}

export default Header;