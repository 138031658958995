import React, {useContext} from 'react';
import { Form, Button} from 'react-bootstrap';
import { useForm } from "react-hook-form";//Validar
import CsvContext from "../../../context/csv/CsvContext";
import ProgramRocContext from '../../../context/programRoc/ProgramRocContext';
import MyModal from "../../Modal/MyModal";
import PreRociadoPoint from "../../../logic/PreRociadoPoint";

const FormProgramRoc = (props) => {

    //Obtener las viviendas del csv
    const CsvsContext = useContext(CsvContext);
    let {houses} = CsvsContext;

    //Extraer los valores del context para programar rociado
    const ProgramRocsContext = useContext(ProgramRocContext);
    const { AddRociadosProgramados } = ProgramRocsContext;

    //validacion
    const { handleSubmit } = useForm();

    const OnSubmit = () => {

        let unicode = props.unicode;
        
        //Guardar en la base de datos
        AddRociadosProgramados(unicode);
        //Creando variable para enviar a funcion Publicity
        let rociado = [];
        rociado.ROC_UNICODE = unicode
            
        
        //Actualizando en mapa
        PreRociadoPoint(houses, rociado);
        //Inicializar inspeccion
        unicode = "";
        
        //Cerrar modal
        props.ChangeModal();
    };

    return (
        <MyModal modal={props.modal} ChangeModal={props.ChangeModal} formTitle={props.formTitle}>
            <Form onSubmit={handleSubmit(OnSubmit)}>   
                <>
                    <Form.Label>Esta seguro que desea programar para rociar en esta vivienda?</Form.Label>
                </>
                
                <Button type='submit'>Acepto</Button> 
            </Form>
        </MyModal>
    );
}

export default FormProgramRoc;