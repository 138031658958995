import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';

import Home from "./layouts/Home";
import NotFound from "./components/NotFound";
import AlertState from './context/alert/AlertState';
import AuthenticationState from './context/authentication/AuthenticationState';
import DenunciationState from './context/denunciation/DenunciationState';
import InspectionState from './context/inspection/InspectionState';
import RociadoState from './context/rociados/RociadoState';
import PublicityState from './context/publicity/PublicityState';
import AgentState from './context/agent/AgentState';
import CimexState from './context/cimex/CimexState';
import CsvState from './context/csv/CsvState';
import SpinnerState from './context/spinner/SpinnerState';
import ProgramRocState from './context/programRoc/ProgramRocState';

function App() {
  return (
    <SpinnerState>
      <AlertState>
        <CsvState>
          <DenunciationState>
            <InspectionState>
              <RociadoState>
                <ProgramRocState>
                  <AgentState>
                    <PublicityState>
                      <CimexState>
                        <AuthenticationState>
                          <Router> 
                            <Switch>
                              <Route exact path="/" component={Home}/>
                              <Route path="" component={NotFound} />
                            </Switch>
                          </Router>
                        </AuthenticationState>
                      </CimexState>
                    </PublicityState>
                  </AgentState>
                </ProgramRocState>
              </RociadoState>
            </InspectionState>
          </DenunciationState>
        </CsvState>
      </AlertState>
    </SpinnerState>
  );
}

export default App;
