import { useLeaflet } from "react-leaflet";
import L from "leaflet";
//import ReactDOM from 'react-dom';
//import { Button } from "react-bootstrap";

const Legend = ({arrayColor, arrayText}) => {
  //Togleclass en react
  //const [isActive, setActive] = useState("false");

  const { map } = useLeaflet();

  /*const HandleToggle = () => {
    setActive(!isActive);
  };*/
  if (map._controlCorners.bottomleft.getElementsByClassName("legend").length === 0){
    const legend = L.control({ position: "bottomleft" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      const grades = arrayText;//,"Inspección Positiva","Inspección Negativa"];
      let labels = [];
      let colors = arrayColor;//,"transparent","black"];

      for (let i = 0; i < grades.length; i++) {
        labels.push(
          '<i style="background:' +colors[i] +'"></i> ' + 
          '<span>'+grades[i] + '</span> '
        );
      }

      /*const btn = <Button className="btn-legend" onClick={HandleToggle}>B</Button>;
      ReactDOM.render(btn, div);
      const box = <div className={isActive ? "show" : "hidden"}>hola</div>*/

      div.innerHTML = "<div class='box-legend show'>"+labels.join("<br>")+"</div>";
      return div;
    };

    legend.addTo(map);
  }

  return null;
}

export default Legend;