import { COLOR_AGENTE } from "../resources";

//AGENTE
const Agent = (houses, agent) => {
    houses.some((house) => {
        if (house.UNICODE === agent.UNICODE) {
            house.colorBorde = COLOR_AGENTE;
            return true;
        }
        return false;
    });

    return houses;
}

export default Agent;