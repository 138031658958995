import React, {useContext} from "react";
import Swal from "sweetalert2";
import { Button } from 'react-bootstrap';

import AuthenticationContext from "../../context/authentication/AuthenticationContext";
import InspectionContext from "../../context/inspection/InspectionContext";

const BtnDataToday = () => {

    const AuthenticationsContext = useContext(AuthenticationContext);
    const { user } = AuthenticationsContext;
    //Extraer los valores del context
    const InspectionsContext = useContext(InspectionContext);
    const { GetInspectionsToday } = InspectionsContext;

    const BtnPressed = () => {
        GetInspectionsToday(user.USU_CUENTA).then(result => {
            let data = [...result.data];
            let html = "";

            data.forEach(element => {
                html = `${html}${element.UNICODE} `
            });

            //Alerta
            Swal.fire({
                title: 'Viviendas Ingresadas',
                html: html
            })
        });
        
    };

    return (
        <Button className="btn-data-today" variant="primary" onClick={BtnPressed}>Ver datos</Button>
    );
}

export default BtnDataToday;