import React from 'react';

import MyModal from "../../../Modal/MyModal";
import Accordion from "../../../../layouts/Accordion";
import FormPublicity from "./FormPublicity";
import FormAgent from "./FormAgent";

//Formulario de denuncia
const FormPublicityAgent = (props) => {
  return (
    <MyModal modal={props.modal} ChangeModal={props.ChangeModal} formTitle={props.formTitle}>
      <Accordion title="Publicidad">
        <FormPublicity {...props}/>
      </Accordion>
      <Accordion title="Agentes">
        <FormAgent {...props}/>
      </Accordion>
    </MyModal>
  );
}

export default FormPublicityAgent;
