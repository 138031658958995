import React, {useState} from "react";

const Accordion = (props) => {
  //State para mostrar acordion
  const [showAccordion, setShowAccordion] = useState( false );
  
  const PressAccordion = () => {
      if (showAccordion)
          setShowAccordion(false);
      else 
          setShowAccordion(true);
  }
  return(
    <div className="wrapper-accordion">
        <div className="accordion-title" onClick={PressAccordion}>{props.title}</div>
        <div className={showAccordion?"accordion-body show":"accordion-body"}>{props.children}</div>
    </div>
  );
}

export default Accordion;