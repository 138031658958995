import { COLOR_PUBLICIDAD } from "../resources";

//Publicidad
const Publicity = (houses, publicity) => {
    houses.some((house) => {
        if (house.UNICODE === publicity.UNICODE) {
            house.colorBorde = COLOR_PUBLICIDAD;
            return true;
        }
        return false;
    });

    return houses;
}

export default Publicity;