import React, { useReducer, useContext } from 'react';
import Swal from "sweetalert2";

import authContext from './AuthenticationContext';
import authReducer from './AuthenticationReducer';
import ClienteAxios from "../../config/axios";
//import tokenAuth from "../../config/tokenAuth";
import { 
    LOGIN_INTO,
    LOGIN_ERROR,
    LOGIN_END,
    BTN_DENUNCIATION,
    BTN_INSPECTION_PASIVE,
    BTN_INSPECTION_ACTIVE,
    BTN_SPRAYED,
    BTN_RETURN
} from '../../types';
import CsvContext from "../csv/CsvContext";
import DenunciationContext from "../denunciation/DenunciationContext";
import InspectionContext from "../inspection/InspectionContext";
import RociadoContext from "../rociados/RociadoContext";
import PublicityContext from "../publicity/PublicityContext";
import AgentContext from "../agent/AgentContext";
import SpinnerContext from "../spinner/SpinnerContext";
import ProgramRocContext from "../programRoc/ProgramRocContext";
import MessageDaysWorked from '../../components/MessageDaysWorked';
import Logic from '../../logic';

const AuthenticationState = props => {
    
    const initialState = {
        //token: localStorage.getItem('token'),
        authenticated: false,
        btnDenunciation: false,
        btnInspectionPasive: false,
        btnInspectionActive: false,
        btnSprayed: false,
        user: {},
        message: null,
        cargando: true
    }

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(authReducer, initialState);
    //Obtener viviendas
    const CsvsContext = useContext(CsvContext);
    const { UpdateHouses, CsvHouses, CsvHealthPosts, CsvParticipantsInmune, CsvClean, CsvDatabases} = CsvsContext;
    //Obtener el denuncias
    const DenunciationsContext = useContext(DenunciationContext);
    const { GetDenunciationsMicrored } = DenunciationsContext;
    //Obtener el inspecciones
    const InspectionsContext = useContext(InspectionContext);
    const { UpdateInspections, inspectionsOffline, GetInspections, AddInspection, CleanInspectionsOffline } = InspectionsContext;
    //Obtener los rociados de la base de datos
    const RociadosContext = useContext(RociadoContext);
    const { spraysOffline, AddRociados, GetRociados, CleanSpraysOffline} = RociadosContext;
    //Obtener el publicidades
    const PublicitiesContext = useContext(PublicityContext);
    const { publicitiesOffline, GetPublicitiesMicrored, AddPublicity, CleanPublicitiesOffline } = PublicitiesContext;
    //Obtener los agentes
    const AgentsContext = useContext(AgentContext);
    const { agentsOffline, GetAgentsMicrored, AddAgent, CleanAgentsOffline } = AgentsContext;
    //Obtener los rociados programados
    const ProgramRociadosContext = useContext(ProgramRocContext);
    const { GetRociadosProgramados } = ProgramRociadosContext;
    //Cambiar estado de spinner
    const SpinnersContext = useContext(SpinnerContext);
    const { HideSpinner } = SpinnersContext;

    //Cuando el usuario inicia sesion
    const Login = async (datos) => {
      try {
        //Comprobando que el username y password sean correctos
        const respuesta = await ClienteAxios.post('/api/acceder', datos);
        
        //Agregando SCORE e inicializandolo
        respuesta.data.SCORE = 0;

        //Almacenando el username que se logio
        await ClienteAxios.post('/api/visitas-app', datos);
        
        /**Obteniendo vivienda de la base de datos */
        //await CsvDatabases(/*respuesta.data.USU_CATCHMENT_AREA*/"z12test", respuesta.data.USU_APP);

        let newHouses = await CsvHouses(respuesta.data.USU_CATCHMENT_AREA, respuesta.data.USU_APP);
        
        if (newHouses) {
          let newInspections = await GetInspections(newHouses, respuesta.data.USU_MICRORED);
          //Actualizando inspecciones
          UpdateInspections(newInspections);
          //Verificar si existe este campo
          if (respuesta.data.USU_CAMPO_FECHA_INICIO) {
            //Mensaje sobre la cantidad de dias trabajados
            MessageDaysWorked( respuesta.data.USU_CAMPO_FECHA_INICIO, respuesta.data.USU_CAMPO_TOTAL_DIAS, newInspections );
          }

          await GetDenunciationsMicrored(respuesta.data.USU_MICRORED);
          await CsvHealthPosts();
          await CsvParticipantsInmune();
          let newRociados = await GetRociados(respuesta.data.USU_MICRORED);
          let new_roc_programados = await GetRociadosProgramados();
          let newPublicities = await GetPublicitiesMicrored(respuesta.data.USU_MICRORED);
          let newAgents = await GetAgentsMicrored(respuesta.data.USU_MICRORED);
          
          const housesLogic = await Logic(respuesta.data, newHouses, newInspections, newRociados, new_roc_programados, newPublicities, newAgents);
          
          //Actualizar viviendas
          UpdateHouses(housesLogic);
          
          dispatch({
            type: LOGIN_INTO,
            payload: respuesta.data
          });
        } else {
          Swal.fire({
            icon: "error",
            title: 'Error CSV',
            text: 'No se encontro el CSV del usuario.'
          })
        }

      } catch (error) {
          
        const alerta = {
          msg: error.response.data.msg,
          category: 'danger'
        };
        
        dispatch({
          type: LOGIN_ERROR,
          payload: alerta
        });
      }
      //Cambiando estado de spinner
      HideSpinner();
      return true;
    }

    //Cierra la sesion del usuario
    const Logout = () => {
        let cantInspectionsOffline = 0;
        let cantSpraysOffline = 0;
        let cantPublicitiesOffline = 0;
        let cantAgentsOffline = 0;
        
        if (inspectionsOffline.length) {
          inspectionsOffline.forEach(inspection => {
            //Guardando registro de inspeccion en la base de datos
            AddInspection(inspection);
            cantInspectionsOffline++;
          });
          CleanInspectionsOffline();    
        }
        if (spraysOffline.length) {
          spraysOffline.forEach(spray => {
            //Guardando registro de rociado en la base de datos
            AddRociados(spray);
            cantSpraysOffline++
          });
          CleanSpraysOffline();    
        }
        if (publicitiesOffline.length) {
          publicitiesOffline.forEach(publicity => {
            //Guardando registro de publicidad en la base de datos
            AddPublicity(publicity);
            cantPublicitiesOffline++;
          });
          CleanPublicitiesOffline();    
        }
        
        if (agentsOffline.length) {
          agentsOffline.forEach(agent => {
            //Guardando registro de agentes en la base de datos
            AddAgent(agent);
            cantAgentsOffline++;
          });
          CleanAgentsOffline();    
        }

        CsvClean();

        dispatch({
            type: LOGIN_END
        });

        return {cantInspectionsOffline, cantSpraysOffline, cantPublicitiesOffline, cantAgentsOffline}
    }

    //Presionan boton de denuncias
    const PressBtnDenunciation = () => {
        dispatch({
            type: BTN_DENUNCIATION
        });
    }

    //Presionan boton de inspecciones pasivas
    const PressBtnInspectionPasive = () => {
        dispatch({
            type: BTN_INSPECTION_PASIVE
        });
    }

    //Presionan boton de inspecciones activas
    const PressBtnInspectionActive = () => {
        dispatch({
            type: BTN_INSPECTION_ACTIVE
        });
    }

    //Presionan boton de rociado
    const PressBtnSprayed = () => {
        dispatch({
            type: BTN_SPRAYED
        });
    }

    //Presionan boton atras
    const PressBtnReturn = () => {
        dispatch({
            type: BTN_RETURN
        });
    }

    /*const isCharacterALetter = (char) => {
        return (/[a-zA-Z]/).test(char)
    }

    const Logic = async() => {
      debugger;
      let newHouses = [...houses];
      if (newHouses.length > 0 && newHouses[0].additional === undefined) {
        newHouses.forEach(house => {
          //Tomar el ultimo caracter del unicode
          let lastCharacter = house.UNICODE.substring(house.UNICODE.length-1);
          if (isCharacterALetter( lastCharacter)) {
            house.additional = 1;//Poner columna de adicionales
          } else {
            house.additional = 0;//Poner columna de adicionales
          }
        });
        //Actualizar viviendas
        UpdateHouses(newHouses);
      }

      /**NOTA: solo pueden ingresar una vez ya que si ingresa dos veces genera errores */
      //Inspecciones
      /*let newInspections = [...inspections];
      if (newInspections.length > 0 && !newInspections[0].flagLogic ) {
        
        //Creando bandera para que solo ingrese una vez
        newInspections[0].flagLogic = 1;

        /*if ("vectorpoint"=== user.USU_APP ){
          newInspections.forEach(visited => {
            //VectorPoint(houses,visited, user);
            console.log("vectorpoint");
          });
        } else if("bluepoint"=== user.USU_APP) {
          newInspections.forEach(visited => {
            //BluePoint(houses,visited, user);
            console.log("bluepoint");
          });
        } else {
          newInspections.forEach(visited => {
            //NoApp(houses,visited);
            console.log("vectorpoint");
          });
        }*/
        //Actualizando inspecciones
        /*UpdateInspections(newInspections);
      }
    }*/

    return(
        <authContext.Provider
            value={{
                //token: state.token,
                authenticated: state.authenticated,
                btnDenunciation: state.btnDenunciation,
                btnInspectionPasive: state.btnInspectionPasive,
                btnInspectionActive: state.btnInspectionActive,
                btnSprayed: state.btnSprayed,
                user: state.user,
                message: state.message,
                cargando: state.cargando,
                Login,
                Logout,
                PressBtnDenunciation,
                PressBtnInspectionPasive,
                PressBtnInspectionActive,
                PressBtnSprayed,
                PressBtnReturn,
            }}
        >
            {props.children}
        </authContext.Provider>
    );
}

export default AuthenticationState;