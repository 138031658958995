import React , {useReducer} from 'react';
import ClienteAxios from "../../config/axios";
import Swal from "sweetalert2";

//Importar archivos de context y reducer
import RociadoContext from "./RociadoContext";
import RociadoReducer from "./RociadoReducer";

//Importar de type
import { BTN_COLINDANTE,
        DISABLED_BTN_COLINDANTE,
        BTN_ADD_ROCIADO,
        DISABLED_BTN_ADD_ROCIADO,
        ADD_ROCIADO,
        GET_ROCIADOS,
        BTN_NO_COLINDANTE,
        DISABLED_BTN_NO_COLINDANTE,
        BTN_NO_ROCIADO,
        DISABLED_BTN_NO_ROCIADO,
        ADD_SPRAY_OFFLINE,
        CLEAN_SPRAYS_OFFLINE
     } from '../../types';

const RociadoState = props => {

    const initialState = {
        rociados:[],
        //Boton para agregar rociado
        statusBtnAddRoc:false,
        statusBtnNoRoc:false,
        colindante: false,
        noColindante: false,
        spraysOffline: []
    }

    //state para llamar al reducer
    const [state, dispatch] = useReducer(RociadoReducer,initialState);

    const PressBtnAddRoc = () => {
        dispatch({
            type: BTN_ADD_ROCIADO
        });
    }

    const UnPressBtnAddRoc = () => {
        dispatch({
            type: DISABLED_BTN_ADD_ROCIADO
        });
    }
    
    const PressBtnNoRoc = () => {
        dispatch({
            type: BTN_NO_ROCIADO
        });
    }

    const UnPressBtnNoRoc = () => {
        dispatch({
            type: DISABLED_BTN_NO_ROCIADO
        })
    }

    const PressBtnColidante = () => {
        dispatch({
            type: BTN_COLINDANTE
        });
    }
    const UnPressBtnColindante = () => {
        dispatch({
            type: DISABLED_BTN_COLINDANTE
        });
    }

    const PressBtnNoColindante = () => {
        dispatch({
            type: BTN_NO_COLINDANTE
        });
    }

    const UnPressBtnNoColindante = () => {
        dispatch({
            type: DISABLED_BTN_NO_COLINDANTE
        });
    }



    //Funcion para obtener los rociados registrados en la BD
    const GetRociados = async (numMicrored) => {
        try{
            let resultado = await ClienteAxios.post(`/api/rociados/microred/${numMicrored}`);

            if (resultado.data.length > 0) {
                dispatch({
                    type:GET_ROCIADOS,
                    payload: resultado.data
                });

                return resultado.data;
            } else {
                return 0;
            }

        }catch (error) {
             console.log(error);
        }
    }

    // Funcion para agregar rociados
    const AddRociados = async (rociados) => {
        try {
            
            if ( navigator.onLine ) {
                const result = await ClienteAxios.post('/api/rociados', rociados);
                
                if (result.data.option === 'error') {
                    console.log(result.data.msg);
                }

                dispatch ({
                    type: ADD_ROCIADO,
                    payload: result.data
                });
            } else {
                dispatch({
                    type: ADD_SPRAY_OFFLINE,
                    payload: rociados
                });
            }
            
        }catch (error) {
            console.log(error)
            Swal.fire({
                icon: "error",
                title: 'Hubo un error',
                text: 'Hubo un error, No se registro intenta nuevamente'
            })
        }
    }

    //Funcion para preguntar si corresponde al formulario de rociado o no
    const IsRociado = ( name ) => {
        let id = name.split("_")[0];
        if ( id === "roc") 
            return true;
        else
            return false;
    }

    const CleanSpraysOffline = () => {
        dispatch({
            type: CLEAN_SPRAYS_OFFLINE
        });
    }

    return (

        <RociadoContext.Provider
            value = {{
                rociados: state.rociados,
                spraysOffline: state.spraysOffline,
                statusBtnAddRoc : state.statusBtnAddRoc,
                statusBtnNoRoc : state.statusBtnNoRoc,
                colindante: state.colindante,
                noColindante: state.noColindante,
                PressBtnColidante,
                UnPressBtnColindante,
                PressBtnNoColindante,
                UnPressBtnNoColindante,
                PressBtnAddRoc,
                UnPressBtnAddRoc,
                PressBtnNoRoc,
                UnPressBtnNoRoc,
                GetRociados,
                AddRociados,
                IsRociado,
                CleanSpraysOffline
            }}
        >
            {props.children}
        </RociadoContext.Provider>
    )

}

export default RociadoState;