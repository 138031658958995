import VectorPoint from "./VectorPoint";
import BluePoint from "./BluePoint";
import NoApp from "./NoApp";
import RociadoPoint from "./RociadoPoint";
import Publicity from "./Publicity";
import Agent from "./Agent";
import PreRociadoPoint from "./PreRociadoPoint";

const isCharacterALetter = (char) => {
    return (/[a-zA-Z]/).test(char)
}

const Logic = (newUser, newHouses, newInspections, newRociados, new_roc_programados, newPublicities, newAgents ) => {
  /**PARA EL FUTURO: ¿Porque se decidio por el momento hacer que cada vez que se inicia sesion se empiece a crear todo de cero?
   * 1.- Pensando en puntaje, si ponemos una columna para puntaje en el USUARIO entonces si de casualidad tenemos que eliminar
   *     una inspeccion entonces tambien tendremos que eliminar el puntaje en el USUARIO.
   * 2.- Pensando en inspecciones, tendriamos que tener en cuenta las columnas popup de inspecciones, rociado y aparte tener 
   *     una columna de color actual, ya que el CSV se va a actualizar directamente en la base de datos.
   */
  if (newHouses && newHouses.length > 0) {
    newHouses.forEach(house => {
      //Tomar el ultimo caracter del unicode
      let lastCharacter = house.UNICODE.substring(house.UNICODE.length-1);
      if (isCharacterALetter( lastCharacter)) {
        house.additional = 1;//Poner columna de adicionales
      } else {
        house.additional = 0;//Poner columna de adicionales
      }
    });
  }

  //Inspecciones
  if (newInspections && newInspections.length > 0 ) {

    if ("vectorpoint"=== newUser.USU_APP ){
      newInspections.forEach(visited => {
        [newHouses,] = VectorPoint(newHouses, visited, newUser);
      });
    } else if("bluepoint"=== newUser.USU_APP) {
      newInspections.forEach(visited => {
        [newHouses,] = BluePoint(newHouses,visited, newUser);
      });
    } else {
      newInspections.forEach(visited => {
        [newHouses,] = NoApp(newHouses,visited, newUser);
      });
    }
  }
  
  //Rociados
  if (newRociados && newRociados.length > 0) {
    newRociados.forEach(visita => {
      [newHouses,] = RociadoPoint(newHouses, visita, newUser.USU_MICRORED);
    });
    new_roc_programados.forEach(porVisitar => {
      newHouses = PreRociadoPoint(newHouses, porVisitar);
    });
  }
  
  //Publicidades (deberia ser en el FOR de houses pero para evitar confusiones lo hace separado) 
  //NOTA: Creo que da lo mismo si es dentro de houses o separado, igual es el mismo tiempo que se demora.
  if (newPublicities && newPublicities.length > 0 ) {
    newPublicities.forEach(publicity => {
      if (publicity.PUB_ACEPTADA === "1") {
        newHouses = Publicity(newHouses, publicity);
      }
    });
  }

  //AGENTES (deberia ser en el FOR de houses pero para evitar confusiones lo hace separado)
  if (newAgents && newAgents.length > 0 ) {
    newAgents.forEach(agent => {
      newHouses = Agent(newHouses, agent);
    });
  }

  return (newHouses);
}

export default Logic;