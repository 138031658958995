import { 
    CSV_HOUSES,
    ADDITIONAL_HOUSING,
    CSV_PARTICIPANTS_INMUNE,
    CSV_HEALTH_POSTS,
    CSV_CLEAN,
    UPDATE_HOUSES
} from '../../types';

export default (state, action) => {
    switch (action.type) {
        case CSV_HOUSES:
            return{
                ...state,
                houses: state.houses.concat(action.payload)
            };
        case ADDITIONAL_HOUSING:
            return{
              ...state,
              boolAdditional : action.payload.semaphore,
              additionalHousing: action.payload
            };
        case CSV_PARTICIPANTS_INMUNE:
            return{
                ...state,
                participantsInmune: action.payload
            };
        case CSV_HEALTH_POSTS:
            return{
                ...state,
                healthPosts: action.payload
            };
        case CSV_CLEAN:
            return{
                houses:[],
                participantsInmune:[],
                healthPosts:[]
            };
        case UPDATE_HOUSES:
            return{
                ...state,
                houses: action.payload
            };
        default:
            return state;
    }
}