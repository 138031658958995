import React, {useState, useContext} from "react";
import { Marker, Popup } from "react-leaflet";
import { Form, Button } from 'react-bootstrap';
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";//Validar

import CsvContext from "../../context/csv/CsvContext";

const MarkerAdditionalHousing = (props) => {
  //Obtener viviendas
  const CsvsContext = useContext(CsvContext);
  const { additionalHousing, SetAdditional } = CsvsContext;

  //Letra de adicionada
  const [letterAdditional, setLetterAdditional] = useState("");
  //validacion
  const { register, handleSubmit, errors } = useForm();
  
  //Referencia para marcador
  let refMarker = {};
  
  const HandleAdditional = () => {
    //Alerta
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Vivienda Adicional',
      text: "Puso la vivienda en la ubicación correcta?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        //Creando UNICODE de vivienda adicionada
        additionalHousing.UNICODE = additionalHousing.UNICODE_BASE + letterAdditional;
        //Desaparece marcador
        additionalHousing.semaphore = false;
        
        //Actualizando variable "additionalHousing"
        SetAdditional(additionalHousing);
      } 
    })
  }

  //Para abrir popup
  const initMarker = ref => {
    if (ref) {
      ref.leafletElement.openPopup()
    }
    refMarker = ref;
  }
  
  const ChangeMarker = () => {
    additionalHousing.LATITUDE = refMarker.leafletElement.getLatLng().lat;
    additionalHousing.LONGITUDE = refMarker.leafletElement.getLatLng().lng;
  }

  const OnChange = e => {
    //Mayusculas y eliminar espacios
    setLetterAdditional(e.target.value.toUpperCase().replace(/ /g, ''));
  };

  const TextAdditional = (param) => {
    let text = "Tiene adicionadas:";
    param.forEach(element => {
      text = text+element;
    });
    return text;
  }

  return (
    <>
      <Marker 
          position={[parseFloat(additionalHousing.LATITUDE),parseFloat(additionalHousing.LONGITUDE)]}
          name={additionalHousing.UNICODE_BASE} 
          key={additionalHousing.UNICODE_BASE} 
          draggable 
          ref={initMarker}  
          onDragEnd={ChangeMarker} 
      >
        <Popup>
          <div>
            <div className="bold">
              Posicione el marcador<br/>
              exactamente donde se<br/>
              ubica la vivienda<br/>
            </div>
            <Form onSubmit={handleSubmit(HandleAdditional)}>   
              <Form.Group>
                  <div>
                    <div className="text-unicode">{additionalHousing.UNICODE_BASE}</div>          
                    <Form.Control
                        className='text-additional'
                        type='text'
                        name= 'letter_additional'
                        defaultValue={letterAdditional}
                        onChange={OnChange}
                        ref={register({ required: true })}
                    />
                  </div>
                  {errors.letter_additional && <span className='alert-custom'>*Campo obligatorio</span>}
                  {additionalHousing.prevAdditional.length === 0? "No tiene viviendas adicionadas": TextAdditional(additionalHousing.prevAdditional)}
              </Form.Group>
              <Button type='submit' variant="success">Guardar</Button>
            </Form>
          </div>
        </Popup>
      </Marker>
    </>
  );
}

export default MarkerAdditionalHousing;