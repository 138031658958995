import React, {useState, useContext} from 'react';
import { Form, Button, Col} from 'react-bootstrap';
import { useForm } from "react-hook-form";//Validar

import AuthenticationContext from "../../../../context/authentication/AuthenticationContext";
import CsvContext from "../../../../context/csv/CsvContext";
import PublicityContext from '../../../../context/publicity/PublicityContext';
import { initPublicidad } from "../../../../resources";
import Publicity from "../../../../logic/Publicity";

//Formulario de denuncia
const FormPublicity = (props) => {
  
  //Variables de usuario
  const AuthenticationsContext = useContext(AuthenticationContext);
  const { user } = AuthenticationsContext;
  //Obtener viviendas
  const CsvsContext = useContext(CsvContext);
  let { houses } = CsvsContext;
  //Extraer los valores del context para publicidad
  const PublicitiesContext = useContext(PublicityContext);
  const { AddPublicity } = PublicitiesContext;

  //validacion
  const { register, handleSubmit, errors } = useForm();
  
  //State para publicidad
  const [currentPublicidad, setCurrentPublicidad] = useState( initPublicidad );

  const { pub_aceptada, pub_observaciones } = currentPublicidad

  const OnChange = e => {
    setCurrentPublicidad({
      ...currentPublicidad,
      [e.target.name] : e.target.type==="number"? parseInt(e.target.value):e.target.value
    });      
  };
    
  const OnSubmit = () => {
    //Poner usuario y microred
    currentPublicidad.usu_cuenta = user.USU_CUENTA.toUpperCase();
    currentPublicidad.usu_microred = user.USU_MICRORED;
    //Poner unicode
    currentPublicidad.unicode = props.unicode;
    
    //Guardar en la base de datos
    AddPublicity(currentPublicidad);
    //Creando variable para enviar a funcion Publicity
    let publicity = {
        UNICODE:currentPublicidad.unicode,
        PUB_ACEPTADA:currentPublicidad.pub_aceptada
    };
    //Actualizando en mapa
    if (publicity.PUB_ACEPTADA === "1") {
      Publicity(houses, publicity);
    }
    //Inicializar inspeccion
    setCurrentPublicidad(initPublicidad);
    
    //Cerrar modal
    props.ChangeModal();
  };
  
  return (
    <Form onSubmit={handleSubmit(OnSubmit)}>
        <Form.Group controlId="pub_aceptada">
          <Form.Label>La vivienda acepto que se ponga publicidad?</Form.Label>
          <Col sm={10}>
            <Form.Check
              type="radio"
              label="Si"
              name="pub_aceptada"
              value="1"
              id="si"
              checked={pub_aceptada === "1"}
              onChange={OnChange}
              ref={register({required: true})}
            />
            <Form.Check
              type="radio"
              label="No"
              name="pub_aceptada"
              value="0"
              id="no"
              checked={pub_aceptada === "0"}
              onChange={OnChange}
              ref={register({required: true})}
            />
          </Col>
          {errors.pub_aceptada && <span className='alert-custom'>*Campo Obligatorio</span>}
        </Form.Group>
        { pub_aceptada==="0" && 
            <>
              {/* PUB_OBSERVACIONES */}
              <Form.Group controlId="pub_observaciones">
                  <Form.Control 
                      type="text"
                      name= 'pub_observaciones'
                      defaultValue= {pub_observaciones}
                      placeholder = "Cual fue el motivo? ..."
                      onChange= {OnChange}
                      ref={register({ required: true })}
                  />
                  {errors.pub_observaciones && <span className='alert-custom'>*Campo obligatorio</span>}
              </Form.Group>
            </>
        }
        <Button type='submit'>Guardar</Button> 
    </Form>
  );
}

export default FormPublicity;
