import { 
    GET_INSPECTIONS,
    ADD_INSPECTION,
    ADD_INSPECTION_OFFLINE,
    CLEAN_INSPECTIONS_OFFLINE,
    UPDATE_INSPECTIONS
} from '../../types';

export default (state, action) => {
    switch (action.type) {
        case GET_INSPECTIONS:
            return{
                ...state,
                inspections: action.payload
            };
        case ADD_INSPECTION:
            return{
                ...state,
                //Por el momento se almacenará en inspections no importa que tenga distintos campos
                //Luego cuando se haga OFFLINE se decidirá que hacer.
                inspections: [...state.inspections, action.payload]
            };
        case ADD_INSPECTION_OFFLINE:
            return{
                ...state,
                inspectionsOffline: [...state.inspectionsOffline, {...action.payload}]
            };
        case CLEAN_INSPECTIONS_OFFLINE:
            return{
                ...state,
                inspectionsOffline: []
            };
        case UPDATE_INSPECTIONS:
            return{
                ...state,
                inspections: action.payload
            };
        default:
            return state;
    }
}