import React, { useReducer } from 'react';
import ClienteAxios from "../../config/axios";
import Swal from "sweetalert2";

import ProgramRocContext from './ProgramRocContext';
import ProgramRocReducer from './ProgramRocReducer';

//Importar de type
import {    GET_ROCIADOS_PROGRAMADOS,
            ADD_ROCIADO_PROGRAMADO
        } from '../../types';

const ProgramRocState = props => {

    const initialState = {
        //Rociados Programados
        roc_programados : []
    }

    const [state, dispatch] = useReducer(ProgramRocReducer, initialState);

    //Funciones

    //Funcion para obtener los rociados programados en la BD
    const GetRociadosProgramados = async () => {
        try{
            const resultado = await ClienteAxios.get('/api/rociados/ROC_PROG');
            
            if (resultado.data.length > 0) {
                dispatch({
                    type:GET_ROCIADOS_PROGRAMADOS,
                    payload: resultado.data
                });

                return resultado.data;
            } else {
                return 0;
            }

        }catch (error) {
             console.log(error);
        }
    };

    const AddRociadosProgramados = async (rociadoProgramado) => {
        console.log("Esta en rociado state");
        console.log(rociadoProgramado);
        let viviendaProgramada = {rociadoProgramado}
        try {
            let resultado = await ClienteAxios.post('/api/rociados/ROC_PROG', viviendaProgramada);
            console.log("Se esta registrando el rociado programado");
            console.log(resultado);
            dispatch ({

                type:ADD_ROCIADO_PROGRAMADO,
                payload:resultado.data
            });
            
            //Alerta
            Swal.fire(
                'Correcto',
                'Se registro correctamente el rociado programado',
                'success'
            )
            
        }catch (error) {
            console.log(error)
            Swal.fire({
                icon: "error",
                title: 'Hubo un error',
                text: 'Hubo un error, No se registro intenta nuevamente'
            })
        }
    }


    return(
        <ProgramRocContext.Provider
            value = {{
                rociados_programados: state.roc_programados,
                GetRociadosProgramados,
                AddRociadosProgramados
            }}
        >
           {props.children}
        </ProgramRocContext.Provider>
    );
}

export default ProgramRocState;