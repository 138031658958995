import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import swDev from './swDev';

import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

/*swDev();*/

// Detectar cambios de conexión
function isOnline() {

  if ( navigator.onLine ) {
      // tenemos conexión
      mdtoast('Online', {
          interaction: true,
          interactionTimeout: 3000,
          actionText: 'OK!',
          type: 'success'
      });


  } else{
      // No tenemos conexión
      mdtoast('Offline', {
          interaction: true,
          actionText: 'OK',
          type: 'error'
      });
  }

}

window.addEventListener('online', isOnline );
window.addEventListener('offline', isOnline );

isOnline();
