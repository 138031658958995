import React, { useReducer } from 'react';
import ClienteAxios from "../../config/axios";

import AgentContext from './AgentContext';
import AgentReducer from './AgentReducer';
import { 
    GET_AGENTS,
    ADD_AGENT_OFFLINE,
    CLEAN_AGENTS_OFFLINE
} from '../../types';
//import SpinnerContext from "../spinner/SpinnerContext";

const AgentState = props => {
    
    const initialState = {
        agents: [],
        agentsOffline: []
    }

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(AgentReducer, initialState);

    //FUNCIONES
    //* Obtener publicidades por MICRORED
    const GetAgentsMicrored = async ( microred ) => {
          
        try {
          let resultado = await ClienteAxios.post(`/api/agentes/${microred}`);
          
          if (resultado.data.length > 0) {
            dispatch({
                type: GET_AGENTS,
                payload: resultado.data
            });  

            return resultado.data;
          } else {
            return 0;
          }
            
        } catch (error) {
            console.log(error);
        }
    }

    //* Agregar publicidad
    const AddAgent = async ( agent ) => {
        
        try {
            if ( navigator.onLine ) {
                let result = await ClienteAxios.post('/api/agentes', agent );
            
                if (result.data.option === 'error') {
                    console.log(result.data.msg);
                }  
            } else {
                dispatch({
                    type: ADD_AGENT_OFFLINE,
                    payload: agent
                });
            }

            return true;
        } catch (error) {
            console.log(error);
        }
    }

    const CleanAgentsOffline = () => {
        dispatch({
            type: CLEAN_AGENTS_OFFLINE
        });
    }

    return(
        <AgentContext.Provider
            value={{
                agents: state.agents,
                agentsOffline: state.agentsOffline,
                GetAgentsMicrored,
                AddAgent,
                CleanAgentsOffline
            }}
        >
            {props.children}
        </AgentContext.Provider>
    );
}

export default AgentState;