import React from "react";

import { COLOR_INSPECCION_POSITIVA, COLOR_INSPECCION_NEGATIVA,
         COLOR_RIESGO_1, COLOR_RIESGO_2, COLOR_RIESGO_3, COLOR_RIESGO_4, COLOR_RIESGO_5,
         P_RIESGO_1, P_RIESGO_2, P_RIESGO_3, P_RIESGO_4, P_RIESGO_5, INSPECTION_TEXT, FECHA_SEGUNDO_ROCIADO_PEDREGAL } from "../resources";

//Funcion para Vectorpoint
/*12-03-2021: Se esta agregando la funcionabilidad de: 
  - Si visitamos una vivienda con riesgo 3 y es negativa, el riesgo de todas las demás viviendas 
    que están en la cuadra bajan un nivel, si es vivienda de nivel 3 baja a 2, si es nivel 2 baja
    a 1 y si es nivel 1 se queda en ese nivel, excepto las viviendas que tienen un mayor nivel 
    por ejemplo 4 y 5 el color de esas viviendas no se ve afectado.
  - Esto solo ocurre cuando una vivienda es visitada e inspeccionada.
*/
const VectorPoint = (houses, visited, user) => {

    //Numero de rociado
    let numRociado = 0;

    //Preguntar si es adicional o no
    if (visited.LAT && visited.LAT!== null && visited.LAT !== "NA") {
      AdditionalVP(houses, visited);
    }
    
    houses.some((house, index) => {
        if (house.UNICODE === visited.UNICODE) {
            
            //Almacenando inspeccion que pertecene a este catchment-area
            //StoreInspection(visited);
            //Esto se pone por que van a haber alguna inspecciones pasadas que luego se les hizo
            //visita como C, LV, R por que antes si era posible hacerlo, ahora en el futuro esto
            //ya no se podra realizar, pero tenemos que mantenerlo
            if (house.STATUS_INSPECCION !== "inspeccion" || visited.STATUS_INSPECCION === "inspeccion") {
                //Almacenar status
                house.STATUS_INSPECCION = visited.STATUS_INSPECCION;
                //Cambiar popup
                house.inspectionText =  <div>Visita Insp: <b>{visited.STATUS_INSPECCION}</b>:<b>{visited.FECHA}</b></div>;
                //Cambiar color
                if ( visited.STATUS_INSPECCION === "inspeccion") {
                    //Puntaje
                    if( visited.USER_NAME === user.USU_CUENTA.toUpperCase()){
                        switch ( house.color ) {
                            case COLOR_RIESGO_5:
                                user.SCORE += P_RIESGO_5;
                                break;
                            case COLOR_RIESGO_4:
                                user.SCORE += P_RIESGO_4;
                                break;
                            case COLOR_RIESGO_3:
                                user.SCORE += P_RIESGO_3;
                                break;
                            case COLOR_RIESGO_2:
                                user.SCORE += P_RIESGO_2;
                                break;
                            case COLOR_RIESGO_1:
                                user.SCORE += P_RIESGO_1;
                                break;
                            default:
                                break;
                        }
                    }
                    
                    if ("1" === visited.INTRA_CHIRIS || "1" === visited.PERI_CHIRIS || true === visited.INTRA_CHIRIS || true === visited.PERI_CHIRIS) {
                        house.color = COLOR_INSPECCION_POSITIVA;
                        //Campo para dibujar circulo azul
                        if (visited.INSP_DEN_COLIN === "denunciante")
                            house.INSP_DEN_COLIN = visited.INSP_DEN_COLIN;    
                    } else {
                        if( houses[index].codeLocBlock ){
                            
                            //Cambiar colores por cuadra                                
                            let indInferior = (index-parseInt(house.order))+1;//Encontrar el indice inferior
                            //Puntero que caminara por todo el grupo
                            let pIndex = index+1;//Apunta a la siguiente vivienda
                            let numColorBase = NumValueColor(house.color);
                            let numColorCurrent = null;
                            //Booleano utilizado para recorrer el while
                            let bool = true;
                            //Disminuir un nivel de riesgo en las viviendas dentro de la cuadra que sean menores o igual al riesgo de la vivienda inspeccionada
                            while ( bool ){
                                if (pIndex === index) {
                                    bool = false;
                                } else if ( houses[pIndex] && houses[index].codeLocBlock === houses[pIndex].codeLocBlock ) {
                                    numColorCurrent = NumValueColor(houses[pIndex].color);
                                    if (numColorBase<=1 || numColorCurrent<=1 || numColorBase < numColorCurrent){
                                        pIndex = pIndex+1;
                                    } else if ( numColorBase >= numColorCurrent  ){
                                        houses[pIndex].color = StringValueColor(numColorCurrent-1);
                                        pIndex = pIndex+1;
                                    }
                                } else {
                                    pIndex = indInferior;
                                }
                            }
                        } 
                        //Lo que siempre se hace con viviendas inspeccionadas negativas
                        house.color = COLOR_INSPECCION_NEGATIVA;
                    }
                }
            }

            //Numero de rociado
            if (user.USU_MICRORED !== "20" && visited.ROC_FECHA < FECHA_SEGUNDO_ROCIADO_PEDREGAL) {
                //Numero de rociado
                numRociado = 1;
            } else {
                numRociado = 2;
            }

            return true;
        } else {
            return false;
        }
    });

    return [houses, numRociado];
}

//Funcion para darle valor numerico al color desde un string
const NumValueColor = (color) => {
    let valorColor = 0;
    switch (color) {
        case COLOR_RIESGO_5:
            valorColor = 5
            break;
        case COLOR_RIESGO_4:
            valorColor = 4
            break;
        case COLOR_RIESGO_3:
            valorColor = 3
            break;
        case COLOR_RIESGO_2:
            valorColor = 2
            break;
        case COLOR_RIESGO_1:
            valorColor = 1
            break;
        default:
            break;
    }
    return valorColor;
}
//Funcion para de un numero darle el valor string al color
const StringValueColor = (color) => {
    let valorColor = 0;
    switch (color) {
        case 5:
            valorColor = COLOR_RIESGO_5
            break;
        case 4:
            valorColor = COLOR_RIESGO_4
            break;
        case 3:
            valorColor = COLOR_RIESGO_3
            break;
        case 2:
            valorColor = COLOR_RIESGO_2
            break;
        case 1:
            valorColor = COLOR_RIESGO_1
            break;
        default:
            break;
    }
    return valorColor;
}

//Adicional en vectorpoint
const AdditionalVP = (houses, additional) => {
  
  //Agregar la vivienda al final del su grupo de cuadra
  houses.some((element, index)=> {
    if (additional.UNICODE_BASE === element.UNICODE) {
      additional.codeLocBlock = element.codeLocBlock;

      let bool = true;
      let newIndex = index+1;
      while (bool) {
        if (houses[newIndex].codeLocBlock === additional.codeLocBlock) {
          newIndex++;
        } else {
          //Continuando al final del grupo con el numero de orden que le corresponde
          additional.order = parseInt(houses[newIndex-1].order)+1;
          additional.LATITUDE = additional.LAT;
          additional.LONGITUDE = additional.LNG;
          //Editando los campos necesarios cuando la adicionada viene desde la base de datos
          if (additional.PERSONAS_PREDIO) {
            additional.additional = 1;
            additional.color = additional.RISK_COLOR;
            additional.initialColor = additional.INITIAL_COLOR;
            additional.inspectionText = INSPECTION_TEXT;
          }

          //Agregando la vivienda adicional en el lugar indicado
          houses.splice(newIndex, 0, additional);
          bool = false;
        }
      }
      return true;
    } else {
      return false;
    }
  });
}

export default VectorPoint;