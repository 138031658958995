//Importar de type
import {    GET_ROCIADOS_PROGRAMADOS,
    ADD_ROCIADO_PROGRAMADO
} from '../../types';

export default (state, action) => {
    switch(action.type) {
        case GET_ROCIADOS_PROGRAMADOS:
            return{
                ...state,
                roc_programados: action.payload
            };
        case ADD_ROCIADO_PROGRAMADO:
            return{
                ...state,
                roc_programados: [...state.roc_programados, action.payload]
            }
        default:
            return state;
    }
}