import { 
    LOGIN_INTO,
    LOGIN_ERROR,
    LOGIN_END,
    BTN_DENUNCIATION,
    BTN_INSPECTION_PASIVE,
    BTN_INSPECTION_ACTIVE,
    BTN_SPRAYED,
    BTN_RETURN
} from '../../types';

export default (state, action) => {

    switch (action.type) {
        case LOGIN_INTO:
            //localStorage.setItem('token', action.payload.token);
            return{
                ...state,
                authenticated: true,
                user: action.payload,
                message: null,
                cargando: false
            };
        case LOGIN_ERROR:
        case LOGIN_END:
            //localStorage.removeItem('token');
            return{
                ...state,
                //token: null,
                user: {},
                authenticated: false,
                btnDenunciation: false,
                btnInspectionPasive: false,
                btnInspectionActive: false,
                btnSprayed: false, 
                message: action.payload,
                cargando: false
            };
        case BTN_DENUNCIATION:
            return{
                ...state,
                btnDenunciation: true,
                btnInspectionPasive: false,
                btnInspectionActive: false,
                btnSprayed: false,    
            };
        case BTN_INSPECTION_PASIVE:
            return{
                ...state,
                btnDenunciation: false,
                btnInspectionPasive: true,
                btnInspectionActive: false,
                btnSprayed: false,    
            };
        case BTN_INSPECTION_ACTIVE:
            return{
                ...state,
                btnDenunciation: false,
                btnInspectionPasive: false,
                btnInspectionActive: true,
                btnSprayed: false,    
            };
        case BTN_SPRAYED:
            return{
                ...state,
                btnDenunciation: false,
                btnInspectionPasive: false,
                btnInspectionActive: false,
                btnSprayed: true,    
            };
        case BTN_RETURN:
            return{
                ...state,
                btnDenunciation: false,
                btnInspectionPasive: false,
                btnInspectionActive: false,
                btnSprayed: false,
            };
        default:
            return state;
    }
}