import { 
    ADD_PUBLICITY_OFFLINE,
    GET_PUBLICITIES,
    CLEAN_PUBLICITIES_OFFLINE
} from '../../types';

export default (state, action) => {
    switch (action.type) {
        case GET_PUBLICITIES:
            return{
                ...state,
                publicities: action.payload
            };
        case ADD_PUBLICITY_OFFLINE:
            return{
                ...state,
                publicitiesOffline: [...state.publicitiesOffline, {...action.payload}]
            };
        case CLEAN_PUBLICITIES_OFFLINE:
            return{
                ...state,
                publicitiesOffline: []
            };
        default:
            return state;
    }
}