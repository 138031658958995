import React, { useReducer } from 'react';
import ClienteAxios from "../../config/axios";

import CimexContext from './CimexContext';
import CimexReducer from './CimexReducer';
import { 
    ADD_CIMEX
} from '../../types';

const CimexState = props => {
    
    const initialState = {
        cimex: []
    }

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(CimexReducer, initialState);
    
    //FUNCIONES
    //* Agregar CIMEX
    const AddCimex = async ( currentCimex ) => {
        
        try {
            const resultado = await ClienteAxios.post('/api/cimex', currentCimex );
            
            dispatch({
                type: ADD_CIMEX,
                payload: resultado.data
            });
            
        } catch (error) {
            console.log("Error en TRY-CATCH de funcion AddCimex: "+error);
        }
    }

    //Funcion para terminar de acomodar todos los campos de CIMEX
    const RecordCimex = ( currentCimex ) => {
        
        //*Haciendo verificaciones de campos
        //Verificaciones en el campo "cimex_alguien_picado_casa_ultimo_anio"
        if (currentCimex.cimex_alguien_picado_casa_ultimo_anio === "1" ){
            //Inicializando a vacio para concatenar string
            currentCimex.cimex_sabe_insecto_pico = '';

            if (currentCimex.cimex_chinches_cama)
                currentCimex.cimex_sabe_insecto_pico += "-chinches_cama";
            if (currentCimex.cimex_chirimachas)
                currentCimex.cimex_sabe_insecto_pico += "-chirimachas";
            if (currentCimex.cimex_mosquitos)
                currentCimex.cimex_sabe_insecto_pico += "-mosquitos";
            if (currentCimex.cimex_zancudos)
                currentCimex.cimex_sabe_insecto_pico += "-zancudos";
            if (currentCimex.cimex_aranias)
                currentCimex.cimex_sabe_insecto_pico += "-aranias";
            if (currentCimex.cimex_pulgas)
                currentCimex.cimex_sabe_insecto_pico += "-pulgas";
            if (currentCimex.cimex_piojos)
                currentCimex.cimex_sabe_insecto_pico += "-piojos";
            if (currentCimex.cimex_garrapatas)
                currentCimex.cimex_sabe_insecto_pico += "-garrapatas";
            if (currentCimex.cimex_pique)
                currentCimex.cimex_sabe_insecto_pico += "-pique";
            if (currentCimex.cimex_hitas)
                currentCimex.cimex_sabe_insecto_pico += "-hitas";
            if (currentCimex.cimex_alacranes)
                currentCimex.cimex_sabe_insecto_pico += "-alacranes";
            if (currentCimex.cimex_otro)
                currentCimex.cimex_sabe_insecto_pico += "-otro";
            //Eliminamos el primer guion
            currentCimex.cimex_sabe_insecto_pico = currentCimex.cimex_sabe_insecto_pico.substr(1);
        }
        //Verificaciones en el campo "cimex_en_su_vivienda"
        if ( currentCimex.cimex_en_su_vivienda !== "1" ) {
            currentCimex.cimex_inspeccion_gratuita = "NA";
        } 
        
        //Verificaciones en el campo "cimex_inspeccion_gratuita"
        if ( currentCimex.cimex_inspeccion_gratuita !== "1" ) {
            currentCimex.cimex_fecha_tentativa_inspeccion = "NA";
            currentCimex.cimex_hora_tentativa_inspeccion = "NA";
        } 
            
    }

    //Funcion para preguntar si corresponde al formulario de cimex o no
    const IsCimex = ( name ) => {
        let id = name.split("_")[0];
        if ( id === "cimex") 
            return true;
        else
            return false;
    }

    return(
        <CimexContext.Provider
            value={{
                cimex: state.cimex,
                AddCimex,
                RecordCimex,
                IsCimex
            }}
        >
            {props.children}
        </CimexContext.Provider>
    );
}

export default CimexState;