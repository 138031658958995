import React, { useReducer} from 'react';
import * as d3 from 'd3';
import ClienteAxios from "../../config/axios";

import CsvContext from './CsvContext';
import CsvReducer from './CsvReducer';
import { 
    //CSV_HOUSES,
    ADDITIONAL_HOUSING,
    CSV_PARTICIPANTS_INMUNE,
    CSV_HEALTH_POSTS,
    CSV_CLEAN,
    UPDATE_HOUSES
} from '../../types';

const CsvState = props => {
    
    const initialState = {
        houses: [],
        participantsInmune: [],
        healthPosts: [],
        boolAdditional: false,
        additionalHousing: {}
    }

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(CsvReducer, initialState);

    //Funciones
    const CsvHouses = async (fileCsv, usuApp) => {
        
        if (Object.keys(fileCsv).length !== 0) {
            const arrayFileCsv = fileCsv.split('&');
            let totalNewResults = [];
            for (let i = 0; i < arrayFileCsv.length; i++) {
                //Obtener CSV
                let pathCsv = require('../../../public/catchment-area/' + arrayFileCsv[i] + '.csv');
                
                try {
                    let results = await d3.csv(pathCsv);
                    
                    let newResults = [];
                    //Eliminando viviendas que no tienen GPS
                    results.forEach(house => {
                        if(house.LATITUDE !== null && house.LATITUDE !== "" && house.LATITUDE !== "NA")
                            newResults.push(house);
                    });
                    //Retornando para que esto pueda ser utilizado por INSPECTIONS
                    totalNewResults = totalNewResults.concat(newResults);
                    
                } catch (error) {
                    console.log(error);
                }
            }
            //Retornando para que esto pueda ser utilizado por INSPECTIONS
            return totalNewResults;
        } else {
            return 0;
        }
    };

    const SetAdditional = ( house ) => {
        dispatch({
            type: ADDITIONAL_HOUSING,
            payload: house
        });
        
    }
          
    const CsvParticipantsInmune = async () => {
        //Obtener CSV
        const fileCsv= "registro_participantes_inmune";
        const pathCsv = require('../../../public/files/' + fileCsv + '.csv');
                
        try {
          const results = await d3.csv(pathCsv);
          
            dispatch({
                type: CSV_PARTICIPANTS_INMUNE,
                payload: results
            });
            
        } catch (error) {
            console.log(error);
        }
    };

    const CsvHealthPosts = async () => {
        //Obtener CSV
        const fileCsv= "PUESTOS DE SALUD AQP_modificado_06022020";
        const pathCsv = require('../../../public/files/' + fileCsv + '.csv');
                
        try {
          const results = await d3.csv(pathCsv);
          
            dispatch({
                type: CSV_HEALTH_POSTS,
                payload: results
            });
            
        } catch (error) {
            console.log(error);
        }
    };
    
    const CsvClean = () => {
        dispatch({
            type: CSV_CLEAN
        });
    }

    const UpdateHouses = (newHouses) => {
        dispatch({
            type: UPDATE_HOUSES,
            payload: newHouses
        });
    }

    /**Obteniendo los datos de la base de datos */
    const CsvDatabases = async(nameTable, app) => {
      
      try {
        let resultado = await ClienteAxios.post('/api/viviendas', {nameTable} );
        
        let data = resultado.data;
        console.log(data);
        
      } catch (error) {
          console.log(error);
      }
    }


    return(
        <CsvContext.Provider
            value={{
                houses: state.houses,
                participantsInmune: state.participantsInmune,
                healthPosts: state.healthPosts,
                boolAdditional: state.boolAdditional,
                additionalHousing: state.additionalHousing,
                CsvHouses,
                SetAdditional,
                CsvParticipantsInmune,
                CsvHealthPosts,
                CsvClean,
                UpdateHouses,
                CsvDatabases
            }}
        >
            {props.children}
        </CsvContext.Provider>
    );
}

export default CsvState;