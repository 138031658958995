import React from 'react'
import { Container, Row, Col} from "react-bootstrap";

const NotFound = () =>
  <Container fluid="md">
    <Col>
      <Row><h3>404 page not found</h3></Row>
      <Row><p>We are sorry but the page you are looking for does not exist.</p></Row>
    </Col>
  </Container>

export default NotFound