import React, { useContext } from "react";
import { Map, TileLayer } from "react-leaflet";

import CircleHouses from "./CircleHouses";
import MarkerAdditionalHousing from "./MarkerAdditionalHousing";
//import MarkerParticipantsInmune from "./MarkerParticipantsInmune";
import MarkerHealthPosts from "./MarkerHealthPosts";
import BtnDataToday from "../BtnDataToday";
import BtnReturn from "../BtnReturn";
import InformationDen from "../Activities/Denunciations/InformationDen";
import AuthenticationContext from "../../context/authentication/AuthenticationContext";
import CsvContext from "../../context/csv/CsvContext";
import {Mean} from "../../resources"
import Legend from "./Legend";
import Score from "../../layouts/Header/Score";
import { COLOR_ROCIADO_POSITIVO, COLOR_ROCIADO_NEGATIVO, COLOR_SEGUNDO_ROCIADO_POSITIVO, COLOR_SEGUNDO_ROCIADO_NEGATIVO } from "../../resources"

const MyMap = props => {
  //Obtener usuario
  const AuthenticationsContext = useContext(AuthenticationContext);
  const { user, btnInspectionPasive, btnInspectionActive, btnSprayed } = AuthenticationsContext;
  //Obtener viviendas
  const CsvsContext = useContext(CsvContext);
  const { houses, boolAdditional } = CsvsContext;  
  //Promedio de latitudes y longitudes
  let lat = Mean(houses,"LATITUDE");
  let lng = Mean(houses,"LONGITUDE");
  //Variales del mapa
  let center = [lat,lng];
  const zoom = 15;

  return (
    
    <Map center={center} zoom={zoom} preferCanvas={true}>
      <Score/>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <BtnReturn props={props}/>
      {btnInspectionActive &&
        <BtnDataToday/>
      }
      {btnInspectionPasive &&
        <InformationDen/>
      }
      <CircleHouses/>
      {boolAdditional &&
        <MarkerAdditionalHousing houses={houses}/>
      }
      {/*<MarkerParticipantsInmune/>*/}
      <MarkerHealthPosts/>
      { user.USU_APP==="vectorpoint" && btnInspectionActive && 
        <Legend arrayColor = {["#FFFFB2","#FECC5C","#FD8D3C","#F03B20","#BD0026"]} arrayText = {["Mas Bajo","Bajo","Medio","Alto","Mas Alto"]}/>
      }
      { btnSprayed &&
        <Legend arrayColor = {[COLOR_ROCIADO_POSITIVO,COLOR_ROCIADO_NEGATIVO,COLOR_SEGUNDO_ROCIADO_POSITIVO,COLOR_SEGUNDO_ROCIADO_NEGATIVO]} arrayText = {["1er Roc. Pos","1er Roc. Neg","2do Roc. Pos","2do Roc. Neg"]}/>
      }
    </Map>
  );
}

export default MyMap;

