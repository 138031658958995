import React, { useContext, useState } from "react";
import { Container, Button } from 'react-bootstrap';

import DenunciationContext from "../../../context/denunciation/DenunciationContext";
import FormDen from "./FormDen";
import MyTable from "./MyTable";
import BtnReturn from "../../BtnReturn";
import Synchronize from "./Synchronize";

const Denunciations = props => {

  //Obtener el state de Alerta
  const DenunciationsContext = useContext(DenunciationContext);
  const { denunciations, selectEdit, PressBtnAddDen, PressBtnEditDen, CleanBtnDen } = DenunciationsContext;

  //Modal
  const [modal, setModal] = useState(false);
  //Titulo del formulario
  const [formTitle, setFormTitle] = useState(null);

  const HandleAdd = () => {
    setFormTitle("Nuevo registro de denuncias");
    ChangeModal();
    PressBtnAddDen();
  }
  const HandleEdit = () => {
    setFormTitle("Editar registro de denuncias");
    ChangeModal();
    PressBtnEditDen();
  }
  const ChangeModal = () => {
    //Cuando se cierra el modal
    if (modal) {
      CleanBtnDen();
    }
    setModal(!modal);
  }

  return(
    <Container>
      <section className='wrap-sync'>
        <h2>Información de Denuncias</h2>
        <Synchronize/>
      </section>
      <section className='group-btn'>
        <Button onClick={HandleAdd}>Agregar</Button>
        <Button className="btn-edit" onClick={HandleEdit} disabled={!selectEdit}>Editar</Button>
        <BtnReturn props={props}/>
      </section>
      <FormDen modal={modal} ChangeModal={ChangeModal} formTitle={formTitle} />
      <MyTable register={denunciations}/>
    </Container>
  );
}

export default Denunciations;