import React, {useState, useContext} from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";//Validar

import AuthenticationContext from "../../../../context/authentication/AuthenticationContext";
import CsvContext from "../../../../context/csv/CsvContext";
import AgentContext from '../../../../context/agent/AgentContext';
import { initAgente, DateFull } from "../../../../resources";
import Agent from "../../../../logic/Agent";

//Formulario de denuncia
const FormAgent = (props) => {
  
  //Variables de usuario
  const AuthenticationsContext = useContext(AuthenticationContext);
  const { user } = AuthenticationsContext;
  //Obtener viviendas
  const CsvsContext = useContext(CsvContext);
  let { houses } = CsvsContext;
  //Extraer los valores del context para agentes
  const AgentsContext = useContext(AgentContext);
  const { AddAgent } = AgentsContext;

  //validacion
  const { register, handleSubmit, errors } = useForm();
  
  //State para agentes
  const [currentAgente, setCurrentAgente] = useState( initAgente );

  const {
    age_nombre,
    age_edad,
    age_telefono,
    age_consentimiento
  } = currentAgente;

  const OnChange = e => {
    setCurrentAgente({
      ...currentAgente,
      [e.target.name] : e.target.type==="number"? parseInt(e.target.value):e.target.value
    });
  };
  const OnChangeCheck = e => {
    setCurrentAgente({
      ...currentAgente,
      [e.target.name] : e.target.checked
    });
  };
    
  const OnSubmit = () => {
    
    //Poner usuario y microred
    currentAgente.usu_cuenta = user.USU_CUENTA.toUpperCase();
    currentAgente.usu_microred = user.USU_MICRORED;
    //Poner unicode
    currentAgente.unicode = props.unicode;
    //Poner fecha
    currentAgente.age_fecha = DateFull(new Date());
    //Guardar age_consentimiento
    currentAgente.age_consentimiento = currentAgente.age_consentimiento? 1:0;
    
    //Guardar en la base de datos
    AddAgent(currentAgente);
    //Creando variable para enviar a funcion Agent
    let agent = {
        UNICODE:currentAgente.unicode
    };
    //Actualizando en mapa
    Agent(houses, agent)
    //Inicializar inspeccion
    setCurrentAgente(initAgente);
    
    //Cerrar modal
    props.ChangeModal();
  };
  
  return (
    <Form onSubmit={handleSubmit(OnSubmit)}>
      {/* AGE_NOMBRE */}
      <Form.Group controlId="age_nombre">
          <Form.Label >Nombre del agente*</Form.Label>
          <Form.Control 
              type="text"
              name= 'age_nombre'
              defaultValue= {age_nombre}
              onChange= {OnChange}
              ref={register({ required: true })}
          />
          {errors.age_nombre && <span className='alert-custom'>*Campo obligatorio</span>}
      </Form.Group>
      {/* AGE_EDAD */}
      <Form.Group controlId="age_edad">
        <Form.Label>Edad del agente*</Form.Label>
        <Form.Control 
          type='number'
          name='age_edad'
          defaultValue={age_edad}
          onChange={OnChange}
          ref={register({ required: true, min: 0, maxLength: 2 })}
        />
        {errors.personas_predio?.type === 'required' && <span className='alert-custom'>*Campo obligatorio</span>}
        {errors.personas_predio?.type === 'min' && <span className='alert-custom'>*No puede haber valor negativo</span>}
        {errors.personas_predio?.type === 'maxLength' && <span className='alert-custom'>*Máximo 99 años</span>}
      </Form.Group>
      {/* AGE_TELEFONO */}
      <Form.Group controlId="age_telefono">
          <Form.Label>Teléfono del Habitante*</Form.Label>
          <Form.Control 
              type='number'
              name='age_telefono'
              defaultValue={age_telefono==='NA'?'':age_telefono}
              onChange={OnChange}
              ref={register({ maxLength: 9 })}
          />
          {errors.age_telefono?.type === 'maxLength' && <span className='alert-custom'>*Maximo 9 numeros</span>}
      </Form.Group>
      {/* AGE_CONSENTIMIENTO */}
      <Form.Group controlId="age_consentimiento">
        <Form.Check 
          type="checkbox" 
          name="age_consentimiento"
          label="Firmo el consentimiento"
          value = {age_consentimiento}
          onChange={OnChangeCheck}
        />
      </Form.Group>     
      <Button type='submit'>Guardar</Button>
    </Form>
  );
}

export default FormAgent;
