import React, {useContext} from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import Header from "../../layouts/Header";
import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import Download from "../Download";

const Activities = () => {
    
    //Variables de usuario
    const AuthenticationsContext = useContext(AuthenticationContext);
    const { PressBtnDenunciation, PressBtnInspectionPasive, PressBtnInspectionActive, PressBtnSprayed } = AuthenticationsContext;
    
    //const GoDenunciations = (e) => {
    const GoDenunciations = (e) => {
        e.preventDefault();
        PressBtnDenunciation();
    }
    const GoMap = ( e, btnPress ) => {
        e.preventDefault();
        

        if(btnPress === 'inspPasive') {
            PressBtnInspectionPasive();
        } else if (btnPress === 'inspActive') {
            PressBtnInspectionActive();
        } else if (btnPress === 'sprayed'){
            PressBtnSprayed();
        }
    }
    
    //Aplicando la logica
    //Logic(user, inspections, rociados, publicities, agents, rociados_programados);

    return (
        <>
            <Header/>
            <Container fluid="md">
                <Row>
                    <Col className="envoltura-actividades">
                        <div id="wrap-button-den" className="wrap-button">
                            <label id='title-button-den' className='title-button'>Denuncias</label>
                            <Button id="btn_actDenuncias" variant="primary" onClick={GoDenunciations}>Denuncias</Button>
                        </div>
                        <div id="wrap-button-ins" className="wrap-button">
                            <label id='title-button-ins' className='title-button'>Inspecciones</label>
                            <Button id="btn_actInspeccionesPasivas" variant="danger" onClick={(e)=>GoMap(e, "inspPasive")}>Inspecciones por Denuncia</Button>
                            <Button id="btn_actInspeccionesActivas" variant="danger" onClick={(e)=>GoMap(e, "inspActive")}>Inspecciones Activas</Button>
                        </div>
                        <div id="wrap-button-roc" className="wrap-button">
                            <label id='title-button-roc' className='title-button'>Rociados</label>
                            <Button id="btn_actRociados" variant="success" onClick={(e)=>GoMap(e, "sprayed")}>Rociado</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Download/>
        </>
    );
}

export default Activities;