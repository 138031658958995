import { 
    SHOW_SPINNER,
    HIDE_SPINNER
} from '../../types';

export default (state, action) => {
    switch (action.type) {
        case SHOW_SPINNER:
            return{
                ...state,
                spinner: true
            };
        case HIDE_SPINNER:
            return{
                ...state,
                spinner: false
            };
        default:
            return state;
    }
}