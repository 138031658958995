import React, { useState, useContext, Fragment} from 'react';
import {useForm} from "react-hook-form";

// estilos react bootstrap
import {Form, Row, Col, Button} from 'react-bootstrap';

// importar
import CsvContext from "../../../context/csv/CsvContext";
import MyModal from "../../Modal/MyModal";
import AuthenticationContext from "../../../context/authentication/AuthenticationContext";
import {    inicioRociado, 
            initAux, 
            MaterialIntra,
            MaterialPeri,
            DateFull, 
            PutNA
        } from "../../../resources/index";
import RociadoContext from "../../../context/rociados/RociadoContext";
import RociadoPoint from "../../../logic/RociadoPoint";
import InspectionContext from "../../../context/inspection/InspectionContext";

const FormRociado = (props) => {

    //Extraer informacion de usuario logeado
    const AuthenticationsContext = useContext(AuthenticationContext);
    const {user} = AuthenticationsContext;
    //Obtener las viviendas del csv
    const CsvsContext = useContext(CsvContext);
    let {houses, UpdateHouses} = CsvsContext;
    //Obtener las inspecciones
    const InspectionsContext = useContext(InspectionContext);
    const {inspections } = InspectionsContext;
    //Validacion
    const { register, handleSubmit, errors } = useForm();

    //State para crear variables de campo en vacio - inicio
    const [currentRociado, setCurrentRociado] = useState(inicioRociado);

    // Variables que no van a ir a la BD
    const [auxiliares, setAuxiliares] = useState(initAux);

    //State para crear las categorias de material predominante INTRA
    const [materialIntra, setMaterialIntra] = useState([
        {id:"materialIntra_ladrillo_picardo", key:1, value:"ladrillo_picardo", isChecked:false , etiqueta: "Ladrillo picardo"} , 
        {id:"materialIntra_ladrillo_noble", key:2, value:"ladrillo_noble", isChecked:false, etiqueta: "Ladrillo noble"} ,
        {id:"materialIntra_bloqueta_picardo", key:3, value:"bloqueta_picardo", isChecked:false, etiqueta: "Bloqueta picardo"},
        {id:"materialIntra_bloqueta_noble", key:4, value:"bloqueta_noble", isChecked:false, etiqueta: "Bloqueta noble"},
        {id:"materialIntra_piedra_picardo", key:5, value:"piedra_picardo", isChecked:false, etiqueta: "Piedra picardo"},
        {id:"materialIntra_piedra_noble", key:6, value:"piedra_noble" , isChecked:false, etiqueta: "Piedra noble"},
        {id:"materialIntra_sillar_picardo", key:7, value:"sillar_picardo", isChecked:false, etiqueta: "Sillar picardo"},
        {id:"materialIntra_sillar_noble", key:8, value:"sillar_noble", isChecked:false, etiqueta: "Sillar noble"},
        {id:"materialIntra_noble", key:9, value:"noble", isChecked:false, etiqueta: "Noble"},
        {id:"materialIntra_adobe", key:10, value:"adobe", isChecked:false, etiqueta:"Adobe"},
        {id:"materialIntra_calamina", key:11, value:"calamina", isChecked:false, etiqueta: "Calamina"},
        {id:"materialIntra_madera", key:12, value:"madera", isChecked:false, etiqueta: "Madera"},
        {id:"materialIntra_estera", key:13, value:"estera", isChecked:false, etiqueta:"Estera"},
        {id:"materialIntra_metal", key:14, value:"metal", isChecked:false, etiqueta:"Metal"},
        {id:"materialIntra_drywall", key:15, value:"drywall", isChecked:false, etiqueta:"Drywall"},
        {id:"materialIntra_eternit", key:16, value:"eternit", isChecked:false, etiqueta:"Eternit"},
        {id:"materialIntra_plastico", key:17, value:"plastico", isChecked:false, etiqueta:"Plastico"},
        {id:"materialIntra_tela", key:18, value:"tela", isChecked:false, etiqueta:"Tela"},
        {id:"materialIntra_carton", key:19, value:"carton", isChecked:false, etiqueta:"Carton"},
        {id:"materialIntra_malla", key:20, value:"malla", isChecked:false, etiqueta:"Malla"}

    ]);

    //State para crear las categorias de material predominante PERI
    const [materialPeri, setMaterialPeri] = useState([
        {id:"materialPeri_ladrillo_picardo", key:1, value:"ladrillo_picardo", isChecked:false , etiqueta: "Ladrillo picardo"} , 
        {id:"materialPeri_ladrillo_noble", key:2, value:"ladrillo_noble", isChecked:false, etiqueta: "Ladrillo noble"} ,
        {id:"materialPeri_bloqueta_picardo", key:3, value:"bloqueta_picardo", isChecked:false, etiqueta: "Bloqueta picardo"},
        {id:"materialPeri_bloqueta_noble", key:4, value:"bloqueta_noble", isChecked:false, etiqueta: "Bloqueta noble"},
        {id:"materialPeri_piedra_picardo", key:5, value:"piedra_picardo", isChecked:false, etiqueta: "Piedra picardo"},
        {id:"materialPeri_piedra_noble", key:6, value:"piedra_noble" , isChecked:false, etiqueta: "Piedra noble"},
        {id:"materialPeri_sillar_picardo", key:7, value:"sillar_picardo", isChecked:false, etiqueta: "Sillar picardo"},
        {id:"materialPeri_sillar_noble", key:8, value:"sillar_noble", isChecked:false, etiqueta: "Sillar noble"},
        {id:"materialPeri_noble", key:9, value:"noble", isChecked:false, etiqueta: "Noble"},
        {id:"materialPeri_adobe", key:10, value:"adobe", isChecked:false, etiqueta:"Adobe"},
        {id:"materialPeri_calamina", key:11, value:"calamina", isChecked:false, etiqueta: "Calamina"},
        {id:"materialPeri_madera", key:12, value:"madera", isChecked:false, etiqueta: "Madera"},
        {id:"materialPeri_estera", key:13, value:"estera", isChecked:false, etiqueta:"Estera"},
        {id:"materialPeri_metal", key:14, value:"metal", isChecked:false, etiqueta:"Metal"},
        {id:"materialPeri_drywall", key:15, value:"drywall", isChecked:false, etiqueta:"Drywall"},
        {id:"materialPeri_eternit", key:16, value:"eternit", isChecked:false, etiqueta:"Eternit"},
        {id:"materialPeri_plastico", key:17, value:"plastico", isChecked:false, etiqueta:"Plastico"},
        {id:"materialPeri_tela", key:18, value:"tela", isChecked:false, etiqueta:"Tela"},
        {id:"materialPeri_carton", key:19, value:"carton", isChecked:false, etiqueta:"Carton"},
        {id:"materialPeri_malla", key:20, value:"malla", isChecked:false, etiqueta:"Malla"}

    ]);

    //Extraer los datos que se enviaron desde context de Rociado
    const RociadosContext = useContext(RociadoContext);
    const {colindante, noColindante, statusBtnAddRoc, statusBtnNoRoc, AddRociados, UnPressBtnAddRoc, UnPressBtnColindante, UnPressBtnNoColindante, UnPressBtnNoRoc} = RociadosContext;

    const onChange = (e) => {
        
        //Para modificar el state currentRociado se utiliza su funcion
        setCurrentRociado({
            ...currentRociado,
            [e.target.name]: e.target.type==="number"? parseInt(e.target.value):e.target.value
        })
    }

    const onChangeCheck = (e) => {
        //Para modificar el state de los auxiliares, especficamente cuando es check
        setAuxiliares({
            ...auxiliares,
            [e.target.name]: e.target.checked
        });
   };

   const onChangeCheckIntra = (e) => {
       /*let categoriasI = materialIntra
       categoriasI.map( categoria => {
           if(categoria.value  === e.target.value) {
               categoria.isChecked = e.target.checked
            }
        })*/

        materialIntra.map( materialI => {
            if(materialI.value === e.target.value) {
                materialI.isChecked = e.target.checked;
            }
            return true;
        })
   };

    const onChangeCheckPeri = (e) => {
        /*let categoriasP = materialPeri
        categoriasP.map( categoriaP => {
            if(categoriaP.value === e.target.value) {
                categoriaP.isChecked = e.target.checked
            }
        })*/

        materialPeri.map( materialP => {
            if(materialP.value === e.target.value) {
                materialP.isChecked = e.target.checked;
            }
            return true;
        })
        
    };

    const OnSubmit = () => {

        //poner usuario
        let usuario = (user.USU_CUENTA).toUpperCase();
        currentRociado.usu_cuenta = usuario;

        //poner microred
        let microred = user.USU_MICRORED;
        currentRociado.usu_microred = microred;

        //UNICODE
        currentRociado.roc_unicode = props.unicode;

        //cambiando el formato de la fecha
        currentRociado.roc_fecha = DateFull(currentRociado.roc_fecha);

        //Colocar colindante
        if(colindante === true) {
            currentRociado.roc_colindante = "1"
        }

        let materialesIntra = MaterialIntra(materialIntra);
        currentRociado.roc_intra_material_predominante = materialesIntra;

        let materialesPeri = MaterialPeri(materialPeri);
        currentRociado.roc_peri_material_predominante = materialesPeri;

        if(currentRociado.roc_tratamiento_residual === "T"){
            let superficieT = (currentRociado.roc_cant_insecticida * 165).toString();
            currentRociado.roc_superficie_tratada = superficieT;
        }
        else{
            if(currentRociado.roc_tratamiento_residual === "DES"){
                if(currentRociado.roc_deshabitada_rociada === "1"){
                    let superficieT = (currentRociado.roc_cant_insecticida * 165).toString();
                    currentRociado.roc_superficie_tratada = superficieT;
                }
                else{
                    currentRociado.roc_cant_insecticida = "NA"
                    currentRociado.roc_superficie_tratada = "NA"
                }
            }else{
                currentRociado.roc_cant_insecticida = "NA"
                currentRociado.roc_superficie_tratada = "NA"
            }
        }

        if(roc_techo !== true) {
            currentRociado.roc_techo_cant_perros = "NA"
            currentRociado.roc_techo_cant_gatos = "NA"
            currentRociado.roc_techo_cant_aves_corral = "NA"
            currentRociado.roc_techo_cant_cuyes = "NA"
            currentRociado.roc_techo_cant_conejos = "NA"

        }

        if(roc_patio !== true) {
            currentRociado.roc_patio_cant_perros = "NA"
            currentRociado.roc_patio_cant_gatos = "NA"
            currentRociado.roc_patio_cant_aves_corral = "NA"
            currentRociado.roc_patio_cant_cuyes = "NA"
            currentRociado.roc_patio_cant_conejos = "NA"
        }

        if(roc_intra_animales !== true) {
            currentRociado.roc_intra_cant_perros = "NA"
            currentRociado.roc_intra_cant_gatos = "NA"
            currentRociado.roc_intra_cant_aves_corral = "NA"
            currentRociado.roc_intra_cant_cuyes = "NA"
            currentRociado.roc_intra_cant_conejos = "NA"
        }

        // cambiando todos los vacios por NA
        PutNA(currentRociado);
        
        //Verificar si es ADD
        if(statusBtnAddRoc === true || statusBtnNoRoc === true) {
            
            let visitaRociado = [];
            visitaRociado.UNICODE = currentRociado.roc_unicode;
            visitaRociado.USU_MICRORED = currentRociado.usu_microred;
            visitaRociado.ROC_FECHA = currentRociado.roc_fecha;
            visitaRociado.ROC_TRATAMIENTO_RESIDUAL = currentRociado.roc_tratamiento_residual;
            visitaRociado.ROC_DESHABITADA_ROCIADA = currentRociado.roc_deshabitada_rociada;
            visitaRociado.ROC_INTRA_CANT_CAPTURADOS = currentRociado.roc_intra_cant_capturados;
            visitaRociado.ROC_PERI_CANT_CAPTURADOS = currentRociado.roc_peri_cant_capturados;
            visitaRociado.ROC_COLINDANTE = currentRociado.roc_colindante;
            visitaRociado.ROC_COLINDANTE_INSP = currentRociado.roc_colindante_insp;

            //Actualizar el mapa
            let [newHouses, numRociado] = RociadoPoint(houses, visitaRociado, microred);
            
            //Numero de rociado
            currentRociado.roc_numero = numRociado;
            //Guardar en base de datos
            AddRociados(currentRociado);

            //Actualizar viviendas
            UpdateHouses(newHouses);

            setCurrentRociado(inicioRociado);
            setAuxiliares(initAux);
        }
        if(colindante === true){
            UnPressBtnColindante();
        }

        if(noColindante === true){
            UnPressBtnNoColindante();
        }
        
        UnPressBtnAddRoc();
        UnPressBtnNoRoc();
        props.ChangeModal();
    }

    //Extraer los datos auxiliares
    const { roc_techo,
        roc_patio,
        roc_techo_perro,
        roc_techo_gato,
        roc_techo_ave,
        roc_techo_cuy,
        roc_techo_conejo,
        roc_techo_animales_otro,
        roc_patio_perro,
        roc_patio_gato,
        roc_patio_ave,
        roc_patio_cuy,
        roc_patio_conejo,
        roc_patio_animales_otro,
        roc_intra_animales,
        roc_intra_animales_perro,
        roc_intra_animales_gato,
        roc_intra_animales_ave,
        roc_intra_animales_cuy,
        roc_intra_animales_conejo,
        roc_intra_animales_otro,
        roc_observaciones} = auxiliares;

    //Extraer los datos del state currentRociado
    const { 
        roc_tratamiento_residual,
        roc_deshabitada_rociada,
        roc_colindante,
        roc_colindante_insp,
        roc_nombre_rociador,
        roc_nombre_insecticida,
        roc_jefe_familia,
        roc_cant_personas,
        roc_intra_cant_ambientes,
        roc_intra_ambientes_cerrados,
        roc_intra_grietas,
        roc_intra_cant_capturados,
        roc_peri_cant_ambientes,
        roc_peri_grietas,
        roc_peri_cant_capturados,
        roc_techo_cant_perros,
        roc_techo_cant_gatos,
        roc_techo_cant_aves_corral,
        roc_techo_cant_cuyes,
        roc_techo_cant_conejos,
        roc_techo_text_otros,
        roc_techo_cant_otros,
        roc_patio_cant_perros,
        roc_patio_cant_gatos,
        roc_patio_cant_aves_corral,
        roc_patio_cant_cuyes,
        roc_patio_cant_conejos,
        roc_patio_text_otros,
        roc_patio_cant_otros,
        roc_intra_cant_perros,
        roc_intra_cant_gatos,
        roc_intra_cant_aves_corral,
        roc_intra_cant_cuyes,
        roc_intra_cant_conejos,
        roc_intra_text_otros,
        roc_intra_cant_otros,
        roc_cant_insecticida,
        roc_observaciones_text} = currentRociado;

    return (

        <MyModal modal={props.modal} ChangeModal={props.ChangeModal} formTitle={props.formTitle}>
            <Form onSubmit={handleSubmit(OnSubmit)} >
                <Form.Group as={Col} controlId="roc_unicode">
                    <Form.Label>Codigo de Vivienda</Form.Label>
                    <Form.Control
                        readOnly
                        type="text"
                        name="roc_unicode"
                        value={props.unicode}
                    />
                </Form.Group>
                
                <Form.Group as={Col} controlId="roc_tratamiento_residual">
                    <Form.Label>Tratamiento residual*</Form.Label>
                    <Form.Control
                        as="select"
                        name="roc_tratamiento_residual"
                        onChange={onChange}
                        value={roc_tratamiento_residual}
                        ref={register({required: true})}
                    >
                        <option value="">Seleccione...</option>
                        <option value="T">Tratamiento Integral</option>
                        <option value="C">Cerrado</option>
                        <option value="R">Renuente</option>
                    </Form.Control>
                    {errors.roc_tratamiento_residual && <span className='alert-custom'>*Campo obligatorio</span>}
                </Form.Group>

                <Form.Group as={Col} controlId="roc_colindante">
                    <Form.Label>Relacion con el rociado*</Form.Label>
                    <Col sm={10}>
                        <Form.Check
                            type="radio"
                            label="Es foco"
                            name="roc_colindante"
                            value="NA"//"foco"
                            id="foco"
                            checked={roc_colindante === "NA"}
                            onChange={onChange}
                            ref={register({required: true})}
                        />
                        <Form.Check
                            type="radio"
                            label="Es colindante"
                            name="roc_colindante"
                            value="1"//"colindante"
                            id="colindante"
                            checked={roc_colindante === "1"}
                            onChange={onChange}
                            ref={register({required: true})}
                        />
                    </Col>
                    {errors.roc_colindante && <span className='alert-custom'>*Campo Obligatorio</span>}
                </Form.Group>
                { roc_colindante === "1" &&
                    <Form.Group as={Col} controlId="roc_colindante_insp">
                        <Form.Label>UNICODE de Vivienda Foco*</Form.Label>
                        <Form.Control
                            as="select"
                            name="roc_colindante_insp"
                            value={roc_colindante_insp}
                            onChange={onChange}
                            ref={register({ required: true })}
                        >
                        <option value="">Seleccione Vivienda Original</option>
                            {inspections.uniqueByProperty("UNICODE").map(vivienda =>{
                                if(vivienda.STATUS_INSPECCION === "inspeccion"){
                                    return <option key={vivienda.UNICODE} value={vivienda.UNICODE}>{vivienda.UNICODE}</option>
                                }
                                return true;
                            })}
                        </Form.Control>
                        {errors.roc_colindante_insp && <span className='alert-custom'>*Campo obligatorio</span>}
                    </Form.Group>
                }
                
                {roc_tratamiento_residual === "DES" ?
                    (
                        <Form.Group as={Col} controlId="roc_deshabitada_rociada">
                            <Form.Label>La vivienda fue rociada?*</Form.Label>
                            <Form.Control
                                as="select"
                                name="roc_deshabitada_rociada"
                                onChange={onChange}
                                value={roc_deshabitada_rociada}
                                ref={register({required: true})}
                            >
                                <option value="">Seleccione...</option>
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </Form.Control>
                            {errors.roc_deshabitada_rociada && <span className='alert-custom'>*Campo Obligatorio</span>}
                        </Form.Group>
                    ):null
                }

                {roc_tratamiento_residual === "T" ||  roc_deshabitada_rociada === "1" ?                    
                    (
                        <Fragment>
                            { colindante 
                                ?(<Fragment>
                                    <strong>COLINDANTES</strong>
                                    <Form.Group as={Col} controlId="roc_colindante_insp">
                                        <Form.Label>UNICODE de Vivienda Foco*</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="roc_colindante_insp"
                                            value={roc_colindante_insp}
                                            onChange={onChange}
                                            ref={register({ required: true })}
                                        >
                                            <option value="">Seleccione Vivienda Original</option>
                                            {inspections.map(vivienda =>{
                                                if(vivienda.STATUS_INSPECCION === "inspeccion"){
                                                    return <option key={vivienda.UNICODE} value={vivienda.UNICODE}>{vivienda.UNICODE}</option>
                                                }
                                                return true;
                                            })}
                                        </Form.Control>
                                        {errors.roc_colindante_insp && <span className='alert-custom'>*Campo obligatorio</span>}
                                    </Form.Group>
                                </Fragment>)
                                :null
                            }
                            <Form.Group as={Col} controlId="roc_nombre_rociador">
                                <Form.Label>Nombre del Rociador*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="roc_nombre_rociador"
                                    onChange={onChange}
                                    value={roc_nombre_rociador}
                                    ref={register({required: true})}
                                />
                                {errors.roc_nombre_rociador && <span className='alert-custom'>*Campo Obligatorio</span>}
                            </Form.Group>
                            <Form.Group as={Col} controlId="roc_nombre_insecticida">
                                <Form.Label>Que insecticida esta utilizando?*</Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                        type="radio"
                                        label="Deltametrina (60ml)"
                                        name="roc_nombre_insecticida"
                                        value="deltametrina"
                                        id="deltametrina"
                                        checked={roc_nombre_insecticida === "deltametrina"}
                                        onChange={onChange}
                                        ref={register({required: true})}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Lambdacialotrina (60ml)"
                                        name="roc_nombre_insecticida"
                                        value="lambdacialotrina"
                                        id="lambdacialotrina"
                                        checked={roc_nombre_insecticida === "lambdacialotrina"}
                                        onChange={onChange}
                                        ref={register({required: true})}
                                    />
                                </Col>
                                {errors.roc_nombre_insecticida && <span className='alert-custom'>*Campo Obligatorio</span>}
                            </Form.Group>
                            <Form.Group as={Col} controlId="roc_jefe_familia">
                                <Form.Label>Nombre del jefe de familia*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="roc_jefe_familia"
                                    onChange={onChange}
                                    value={roc_jefe_familia}
                                    ref={register({required: true})}
                                />
                                {errors.roc_jefe_familia && <span className='alert-custom'>*Campo Obligatorio</span>}
                            </Form.Group>
                            <Form.Group as={Col} controlId="roc_cant_personas">
                                <Form.Label>Numero de personas*</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="roc_cant_personas"
                                    onChange={onChange}
                                    value={roc_cant_personas}
                                    ref={register({required: true})}
                                />
                                {errors.roc_cant_personas && <span className='alert-custom'>*Campo Obligatorio</span>}
                            </Form.Group>

                            <strong>Division-Intra domicilio</strong>

                            <Form.Row>
                                <Form.Group as={Col} controlId="roc_intra_cant_ambientes">
                                    <Form.Label>Numero de ambientes*</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="roc_intra_cant_ambientes"
                                        onChange={onChange}
                                        value={roc_intra_cant_ambientes}
                                        ref={register({required: true})}
                                    />
                                    {errors.roc_intra_cant_ambientes && <span className='alert-custom'>*Campo Obligatorio</span>}
                                </Form.Group>
                                <Form.Group as={Col} controlId="roc_intra_ambientes_cerrados">
                                    <Form.Label>Numero de ambientes cerrados</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="roc_intra_ambientes_cerrados"
                                        onChange={onChange}
                                        value={roc_intra_ambientes_cerrados}
                                    />
                                </Form.Group>
                            </Form.Row>
                                <Form.Group as={Col} >
                                    <Form.Label>Material Predominante</Form.Label>
                                    {materialIntra.map(material => (
                                        <Form.Check
                                            type="checkbox"
                                            key = {material.key}
                                            //checked = {material.isChecked}
                                            value = {material.value}
                                            id = {material.id}
                                            onChange = {onChangeCheckIntra}
                                            label = {material.etiqueta}
                                                
                                        />
                                    ))}
                                </Form.Group>
                                <Form.Row>
                                    <Col>
                                        <Form.Group as={Col} controlId="roc_intra_grietas">
                                            <Form.Label>Grietas*</Form.Label>
                                            <Form.Check
                                                type="radio"
                                                label="Si"
                                                name="roc_intra_grietas"
                                                value="1"
                                                id="roc_intra_grietas_si"
                                                checked={roc_intra_grietas === "1"}
                                                onChange={onChange}
                                                ref={register({required: true})}
                                            />
                                            <Form.Check
                                                type="radio"
                                                label="No"
                                                name="roc_intra_grietas"
                                                value="0"
                                                id="roc_intra_grietas_no"
                                                checked={roc_intra_grietas === "0"}
                                                onChange={onChange}
                                                ref={register({required: true})}
                                            />
                                            {errors.roc_intra_grietas && <span className='alert-custom'>*Campo Obligatorio</span>}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group as={Col} controlId="roc_intra_cant_capturados">
                                            <Form.Label>Numero de triatominos capturados*</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="roc_intra_cant_capturados"
                                                onChange={onChange}
                                                min="0"
                                                defaultValue={roc_intra_cant_capturados}
                                                ref={register({required: true})}
                                            />
                                            {errors.roc_intra_cant_capturados && <span className='alert-custom'>*Campo Obligatorio</span>}
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <strong>Division-Peri domicilio</strong>

                                <Form.Group as={Col} controlId="roc_peri_cant_ambientes">
                                    <Form.Label>Numero de Ambientes*</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="roc_peri_cant_ambientes"
                                        onChange={onChange}
                                        value={roc_peri_cant_ambientes}
                                        ref={register({required: true})}
                                    />
                                    {errors.roc_peri_cant_ambientes && <span className='alert-custom'>*Campo Obligatorio</span>}
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <Form.Label>Material Predominante</Form.Label>
                                    {materialPeri.map(materialP => (
                                        <Form.Check
                                            type="checkbox"
                                            key = {materialP.key}
                                            //checked = {material.isChecked}
                                            value = {materialP.value}
                                            id = {materialP.id}
                                            onChange = {onChangeCheckPeri}
                                            label = {materialP.etiqueta}     
                                        />
                                    ))}
                                </Form.Group>
                                <Form.Row>
                                    <Col>
                                        <Form.Group as={Col} controlId="roc_peri_grietas">
                                            <Form.Label>Grietas*</Form.Label>
                                            <Form.Check
                                                type="radio"
                                                label="Si"
                                                name="roc_peri_grietas"
                                                value="1"
                                                id="roc_peri_grietas_si"
                                                checked={roc_peri_grietas === "1"}
                                                onChange={onChange}
                                                ref={register({required: true})}
                                            />
                                            <Form.Check
                                                type="radio"
                                                label="No"
                                                name="roc_peri_grietas"
                                                value="0"
                                                id="roc_peri_grietas_no"
                                                checked={roc_peri_grietas === "0"}
                                                onChange={onChange}
                                                ref={register({required: true})}
                                            />
                                            {errors.roc_peri_grietas && <span className='alert-custom'>*Campo Obligatorio</span>}
                                        </Form.Group>
                                        </Col>
                                    <Col>
                                        <Form.Group as={Col} controlId="roc_peri_cant_capturados">
                                            <Form.Label>Numero de triatominos capturados*</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="roc_peri_cant_capturados"
                                                onChange={onChange}
                                                min="0"
                                                value={roc_peri_cant_capturados}
                                                ref={register({required: true})}
                                            />
                                            {errors.roc_peri_cant_capturados && <span className='alert-custom'>*Campo Obligatorio</span>}
                                        </Form.Group>
                                    </Col>
                            </Form.Row>

                            <strong>CRIANZA DE ANIMALES</strong>
                            <div className="container-animals">
                                <Form.Group as={Col} controlId="roc_techo">
                                    <Form.Check
                                        type="checkbox"
                                        name="roc_techo"
                                        label="Techo"
                                        onChange={onChangeCheck}
                                        checked={roc_techo}
                                    />
                                </Form.Group>

                                {roc_techo &&
                                    (<div className="animals">
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_techo_perro">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_techo_perro"
                                                        label="Perros"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_techo_perro ?
                                                (
                                                    <Col className="col-6">
                                                        <Form.Group controlId="roc_techo_cant_perros">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_techo_cant_perros"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_techo_cant_perros}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_techo_gato">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_techo_gato"
                                                        label="Gatos"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_techo_gato ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_techo_cant_gatos">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_techo_cant_gatos"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_techo_cant_gatos}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_techo_ave">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_techo_ave"
                                                        label="Aves de corral"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_techo_ave ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_techo_cant_aves_corral">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_techo_cant_aves_corral"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_techo_cant_aves_corral}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_techo_cuy">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_techo_cuy"
                                                        label="Cuyes"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_techo_cuy ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_techo_cant_cuyes">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_techo_cant_cuyes"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_techo_cant_cuyes}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_techo_conejo">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_techo_conejo"
                                                        label="Conejos"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_techo_conejo ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_techo_cant_conejos">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_techo_cant_conejos"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_techo_cant_conejos}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-12" >
                                                <Form.Group as={Col} controlId="roc_techo_animales_otro">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_techo_animales_otro"
                                                        label="Otros"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_techo_animales_otro ?
                                                (
                                                    <Fragment>
                                                        <Col className="col-12" >
                                                            <Form.Group as={Col} controlId="roc_techo_text_otros">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="¿Cual es el animal?"
                                                                    name="roc_techo_text_otros"
                                                                    onChange={onChange}
                                                                    value={roc_techo_text_otros}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="col-12" >
                                                            <Form.Group as={Col} controlId="roc_techo_cant_otros">
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Cantidad"
                                                                    name="roc_techo_cant_otros"
                                                                    onChange={onChange}
                                                                    min="0"
                                                                    value={roc_techo_cant_otros}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Fragment>
                                                ):null
                                            }
                                        </Row>
                                    </div>)
                                }
                            </div>
                            <div className="container-animals">
                                <Form.Group as={Col} controlId="roc_patio">
                                    <Form.Check
                                        type="checkbox"
                                        name="roc_patio"
                                        label="Patio"
                                        onChange={onChangeCheck}
                                        checked={roc_patio}
                                    />
                                </Form.Group>
                                {roc_patio ?
                                    (<div className="animals">
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_patio_perro">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_patio_perro"
                                                        label="Perros"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_patio_perro ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_patio_cant_perros">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_patio_cant_perros"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_patio_cant_perros}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_patio_gato">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_patio_gato"
                                                        label="Gatos"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_patio_gato ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_patio_cant_gatos">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_patio_cant_gatos"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_patio_cant_gatos}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6">
                                                <Form.Group as={Col} controlId="roc_patio_ave">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_patio_ave"
                                                        label="Aves de Corral"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_patio_ave ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_patio_cant_aves_corral">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_patio_cant_aves_corral"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_patio_cant_aves_corral}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_patio_cuy">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_patio_cuy"
                                                        label="Cuyes"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_patio_cuy ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_patio_cant_cuyes">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_patio_cant_cuyes"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_patio_cant_cuyes}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_patio_conejo">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_patio_conejo"
                                                        label="Conejos"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_patio_conejo ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_patio_cant_conejos">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_patio_cant_conejos"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_patio_cant_conejos}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-12" >
                                                <Form.Group as={Col} controlId="roc_patio_animales_otro">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_patio_animales_otro"
                                                        label="Otros"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_patio_animales_otro ?
                                                (
                                                    <Fragment>
                                                        <Col className="col-12" >
                                                            <Form.Group as={Col} controlId="roc_patio_text_otros">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="¿Cual es el animal?"
                                                                    name="roc_patio_text_otros"
                                                                    onChange={onChange}
                                                                    value={roc_patio_text_otros}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="col-12" >
                                                            <Form.Group as={Col} controlId="roc_patio_cant_otros">
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Cantidad"
                                                                    name="roc_patio_cant_otros"
                                                                    onChange={onChange}
                                                                    min="0"
                                                                    value={roc_patio_cant_otros}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Fragment>
                                                ):null
                                            }

                                        </Row>

                                    </div>):null
                                }
                            </div>
                            <div className="container-animals">
                                <Form.Group as={Col} controlId="roc_intra_animales">
                                    <Form.Check
                                        type="checkbox"
                                        name="roc_intra_animales"
                                        label="Intra-Domicilio"
                                        onChange={onChangeCheck}
                                        checked={roc_intra_animales}
                                    />
                                </Form.Group>
                                {roc_intra_animales ?
                                    (<div className="animals">
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_intra_animales_perro">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_intra_animales_perro"
                                                        label="Perros"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_intra_animales_perro ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_intra_cant_perros">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_intra_cant_perros"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_intra_cant_perros}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_intra_animales_gato">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_intra_animales_gato"
                                                        label="Gatos"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_intra_animales_gato ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_intra_cant_gatos">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_intra_cant_gatos"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_intra_cant_gatos}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_intra_animales_ave">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_intra_animales_ave"
                                                        label="Aves de corral"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_intra_animales_ave ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_intra_cant_aves_corrall">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_intra_cant_aves_corral"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_intra_cant_aves_corral}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_intra_animales_cuy">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_intra_animales_cuy"
                                                        label="Cuyes"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_intra_animales_cuy ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_intra_cant_cuyes">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_intra_cant_cuyes"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_intra_cant_cuyes}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-6" >
                                                <Form.Group as={Col} controlId="roc_intra_animales_conejo">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_intra_animales_conejo"
                                                        label="Conejos"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_intra_animales_conejo ?
                                                (
                                                    <Col className="col-6" >
                                                        <Form.Group controlId="roc_intra_cant_conejos">
                                                            <Form.Control
                                                                type="number"
                                                                name="roc_intra_cant_conejos"
                                                                onChange={onChange}
                                                                min="0"
                                                                value={roc_intra_cant_conejos}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ):null
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="col-12" >
                                                <Form.Group as={Col} controlId="roc_intra_animales_otro">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="roc_intra_animales_otro"
                                                        label="Otros"
                                                        onChange={onChangeCheck}
                                                        //checked={roc_techo_perro}

                                                    />
                                                </Form.Group>
                                            </Col>
                                            {roc_intra_animales_otro ?
                                                (
                                                    <Fragment>
                                                        <Col className="col-12" >
                                                            <Form.Group as={Col} controlId="roc_intra_text_otros">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="¿Cual es el animal?"
                                                                    name="roc_intra_text_otros"
                                                                    onChange={onChange}
                                                                    value={roc_intra_text_otros}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="col-12" >
                                                            <Form.Group as={Col} controlId="roc_intra_cant_otro">
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Cantidad"
                                                                    name="roc_intra_cant_otro"
                                                                    onChange={onChange}
                                                                    min="0"
                                                                    value={roc_intra_cant_otros}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Fragment>
                                                ):null
                                            }
                                        </Row>
                                    </div>):null
                                }
                            </div>
                            <Form.Group controlId="roc_cant_insecticida">
                                <Form.Label>Consumo de insecticidas(Número de Cargas)*</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="roc_cant_insecticida"
                                    onChange={onChange}
                                    min="0"
                                    defaultValue={roc_cant_insecticida}
                                    ref={register({required: true})}
                                />
                                {errors.roc_cant_insecticida && <span className='alert-custom'>*Campo Obligatorio</span>}
                            </Form.Group>
                            <Form.Group controlId="roc_superficie_tratada">
                                <Form.Label>Superficie tratada (1 Carga = 165 m2)</Form.Label>
                                <Form.Control
                                    readOnly
                                    type="text"
                                    name="roc_superficie_tratada"
                                    value={165*roc_cant_insecticida}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="roc_observaciones">
                                <Form.Check
                                    type="checkbox"
                                    name="roc_observaciones"
                                    label="Observaciones"
                                    onChange={onChangeCheck}
                                    checked={roc_observaciones}
                                />
                            </Form.Group>

                            {roc_observaciones ?
                                (
                                    <Form.Group as={Col} controlId="roc_observaciones_text" >
                                        <Form.Label>Observaciones</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="roc_observaciones_text"
                                            onChange={onChange}
                                            value={roc_observaciones_text}
                                            ref={register({required: true})}
                                        />
                                        {errors.roc_observaciones_text && <span className='alert-custom'>*Campo Obligatorio</span>}
                                    </Form.Group>
                                ):null
                            }
                        </Fragment>
                    ):null
                }
                <Button type='submit'> Guardar </Button>

            </Form>
        </MyModal>

    );
}

export default FormRociado;