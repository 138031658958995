import React, {useContext} from "react";
import { Button } from 'react-bootstrap';

import AuthenticationContext from "../../context/authentication/AuthenticationContext";

const BtnReturn = (props) => {

    const AuthenticationsContext = useContext(AuthenticationContext);
    const { PressBtnReturn } = AuthenticationsContext;
    
    const BtnReturn = () => {
        PressBtnReturn();
    };

    return (
        <Button className="btn-back" variant="primary" onClick={BtnReturn}>Atrás</Button>
    );
}

export default BtnReturn;