import { 
    ADD_AGENT_OFFLINE,
    GET_AGENTS,
    CLEAN_AGENTS_OFFLINE
} from '../../types';

export default (state, action) => {
    switch (action.type) {
        case GET_AGENTS:
            return{
                ...state,
                agents: action.payload
            };
        case ADD_AGENT_OFFLINE:
            return{
                ...state,
                agentsOffline: [...state.agentsOffline, {...action.payload}]
            };
        case CLEAN_AGENTS_OFFLINE:
            return{
                ...state,
                agentsOffline: []
            };
        default:
            return state;
    }
}