import { COLOR_PRE_ROCIADO } from "../resources";

//Funcion para rociados
const PreRociadoPoint = (houses, porVisitar) => {

    houses.some(house => {
        if (porVisitar.ROC_UNICODE === house.UNICODE) {
            if(house.STATUS_ROCIADO === undefined){
                house.colorBordeRociado = COLOR_PRE_ROCIADO
            }
            return true;
        }else {
            return false;
        }
    });

    return houses;
}

export default PreRociadoPoint;