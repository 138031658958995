import React, { useState } from 'react';
import { Button } from "react-bootstrap";

import ExportCSV from "./ExportCSV";

const Download = () => {
    
    //State para mostrar descargas
    const [showDownload, setShowDownload] = useState( false );

    const PressDownload = () => {
        if (showDownload)
            setShowDownload(false);
        else 
            setShowDownload(true);
    }

    return (
        <section>
            <div id="envoltura-descargar" className={showDownload?"custom-extend":null}>
                <ExportCSV cssId="btn-descargar-denuncias" btnName="Descargar Denuncias" option='btnDenunciations' fileName={"datos-denuncias"}/>
                <ExportCSV cssId="btn-descargar-inspecciones" btnName="Descargar Inspecciones" option='btnInspections' fileName={"datos-inspecciones"}/>
                <ExportCSV cssId="btn-descargar-rociados" btnName="Descargar Rociados" option='btnRociados' fileName={"datos-rociados"}/>
            </div>
            <Button id="descargar" className={showDownload?"custom-extend":null} onClick={PressDownload}>DESCARGAR</Button>
        </section>
    );

}

export default Download;