import React, { useContext } from "react";
import { Button } from 'react-bootstrap';
import { withRouter } from "react-router";
import Swal from "sweetalert2";
//import iconPerson from "../../icons/icon-person.png";

import AuthenticationContext from "../../context/authentication/AuthenticationContext";

const SignOut = (props) => {

    //Obtener el state de Usuario
    const AuthenticationsContext = useContext(AuthenticationContext);
    const { user, Logout } = AuthenticationsContext;

    const BtnSignOut = () => {
        
        if ( navigator.onLine ) {
            Swal.fire({
                title: 'Desea salir?',
                text: "Esta seguro que desea salir de la aplicación?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.isConfirmed) {
                    let x = Logout();
                    
                    Swal.fire(
                    'Datos sincronizados!',
                    `Inspecciones: ${x.cantInspectionsOffline}  |
                     Rociados: ${x.cantSpraysOffline}  |
                     Publicidades: ${x.cantPublicitiesOffline}`,
                    'success'
                    )
                }
            })
            
        } else {
            Swal.fire({
                title: 'No tiene internet',
                text:'No puede cerrar sesión si no tiene conexión a internet',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                }
            })
        }
    }

    return(
        <>
            <div className="sign-out">
                {/*<img src={iconPerson} alt="imagen-persona"/>*/}
                <div>Bienvenido <b>{user.USU_CUENTA}</b></div>
                <Button variant="danger" onClick={BtnSignOut}>Cerrar Sesión</Button>
            </div>
        </>
    );
}

export default withRouter((SignOut));