import React, {useState, useContext} from 'react';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import {Calendar} from 'primereact/calendar';//Fecha
import 'primereact/resources/themes/nova-light/theme.css';//Fecha
import 'primereact/resources/primereact.min.css';//Fecha
import 'primeicons/primeicons.css';//Fecha
import { useForm } from "react-hook-form";//Validar

import AlertContext from '../../../context/alert/AlertContext';
import AuthenticationContext from "../../../context/authentication/AuthenticationContext";
import CsvContext from "../../../context/csv/CsvContext";
import DenunciationContext from '../../../context/denunciation/DenunciationContext';
import InspectionContext from '../../../context/inspection/InspectionContext';
import CimexContext from '../../../context/cimex/CimexContext';
import MyModal from "../../Modal/MyModal";
import { es, DateFull, SeveralDates, initInspection, initCimex, inicioRociado, PutNA, COLOR_BASE_ROCIADO } from "../../../resources";
import VectorPoint from "../../../logic/VectorPoint";
import BluePoint from "../../../logic/BluePoint";
import NoApp from "../../../logic/NoApp";
import RociadoPoint from "../../../logic/RociadoPoint";
import RociadoContext from "../../../context/rociados/RociadoContext";

//Formulario de denuncia
const FormInsp = (props) => {

    //Extraer los valores del context
    const AlertsContext = useContext(AlertContext);
    const { alert } = AlertsContext;
    //Variables de usuario
    const AuthenticationsContext = useContext(AuthenticationContext);
    const { user, btnInspectionPasive, btnSprayed } = AuthenticationsContext;
    //Obtener viviendas
    const CsvsContext = useContext(CsvContext);
    let { houses, additionalHousing, UpdateHouses } = CsvsContext;
    //Obtener variables de denuncias
    const DenunciationsContext = useContext(DenunciationContext);
    const { denunciations } = DenunciationsContext;
    //Extraer los valores del context
    const InspectionsContext = useContext(InspectionContext);
    const { inspections, AddInspection, RecordInspection } = InspectionsContext;
    //Extraer los valores del context para CIMEX
    const CimexsContext = useContext(CimexContext);
    const { IsCimex } = CimexsContext;
    //Extraer los datos que se enviaron desde context de Rociado
    const RociadosContext = useContext(RociadoContext);
    const {colindante, AddRociados, IsRociado, UnPressBtnAddRoc} = RociadosContext;

    //Extraer los valores del context
    //const CimexsContext = useContext(CimexContext);
    //const { cimex } = CimexsContext;
    
    //validacion
    const { register, handleSubmit, errors } = useForm();
    
    //State para inspecciones
    const [currentInspection, setCurrentInspection] = useState( initInspection );
    
    //State para cimex
    const [currentCimex, setCurrentCimex] = useState( initCimex );
    //State Rociado
    const [currentRociado, setCurrentRociado] = useState(inicioRociado);

    //Consulta si la vivienda inspeccionada es o no la vivienda que realizo la denuncia
    let haveComplaint = false;
    //Almacenando "den_cant_colindantes" que se tiene hasta ese momento
    let denCantColindantes = 0;
    
    //Extraer de valores de inspeccion
    const {
        den_id_custom,
        insp_den_colin,
        observaciones,//No DB
        obs_unicode,
        obs_text1,//No DB
        obs_text2,//No DB
        //obs_text,
        fecha,
        insp_por_radio_bool,
        insp_por_radio,
        caract_predio,
        tipo_lp,
        status_inspeccion,
        entrevista,
        motivo_volver,
        fecha_volver,
        renuente,
        renuente_otro,//No DB
        insp_habitante_telefono,
        intra_inspeccion,
        intra_chiris,
        intra_rastros,
        peri_inspeccion,
        peri_chiris,
        peri_rastros,
        personas_predio,
        perros,
        cant_perros,
        gatos,
        cant_gatos,
        aves_corral,
        cant_aves_corral,
        cuyes,
        cant_cuyes,
        conejos,
        cant_conejos,
        otros,
        text_otros,
        cant_otros,
        relacion_denuncia // NO DB
    } = currentInspection;

    //Extraer los datos del state currentRociado
    const { 
        roc_colindante_insp
    } = currentRociado;

    //Extraer de valores para cimex de inspeccion
    /*const {  
        cimex_alguien_picado_casa_ultimo_anio,
        cimex_en_su_vivienda,
        cimex_inspeccion_gratuita,
        cimex_fecha_tentativa_inspeccion,
    } = currentCimex;*/
        
    const OnChange = e => {
        
        //Asegurarnos que visualmente se no se vean preguntas 3.1.1 y 3.1.2
        if (e.target.name === 'cimex_en_su_vivienda' && e.target.value !== '1') {
            currentCimex.cimex_inspeccion_gratuita = '999'
        }
        
        if (IsCimex(e.target.name)) {
            setCurrentCimex({
                ...currentCimex,
                [e.target.name] : e.target.type==="number"? parseInt(e.target.value):e.target.value
            });    
        } else if (IsRociado(e.target.name)) {
            setCurrentRociado({
                ...currentRociado,
                [e.target.name] : e.target.type==="number"? parseInt(e.target.value):e.target.value
            });
        } else {
            setCurrentInspection({
                ...currentInspection,
                [e.target.name] : e.target.type==="number"? parseInt(e.target.value):e.target.value
            });
        }
            
    };

    const OnChangeCheck = e => {
        
        if (IsCimex(e.target.name)) {
            setCurrentCimex({
                ...currentCimex,
                [e.target.name] : e.target.checked
            });
        } else {
            setCurrentInspection({
                ...currentInspection,
                [e.target.name] : e.target.checked
            });
        }
    };

    const OnChangeCheckMultiple = e => {
        
        let name = e.target.value;
        if (IsCimex(name)) {
            setCurrentCimex({
                ...currentCimex,
                [name] : e.target.checked
            });
        } else {
            setCurrentInspection({
                ...currentInspection,
                [name] : e.target.checked
            });
        }
    };

    const OnSubmit = () => {
      /**
       * Inspeccion
       */
      //Poner unicode
      currentInspection.unicode = props.unicode;
      //Poner color del riesgo cuando se visita una vivienda
      currentInspection.initial_color = props.initialColor;
      //Poner color del riesgo cuando se visita una vivienda
      currentInspection.risk_color = props.riskColor;
      //Poner usuario
      currentInspection.user_name = user.USU_CUENTA.toUpperCase();
      //MIcrored
      currentInspection.usu_microred = user.USU_MICRORED;
      //Poner codigo de la localidad
      let codeLocality = props.unicode.split(".");
      currentInspection.code_locality = codeLocality[0]+"."+codeLocality[1]+"."+codeLocality[2];
      //Cuadra
      currentInspection.code_loc_block = props.codeLocBlock;
      //Obteniendo solo la fecha en campos calendar
      currentInspection.fecha = DateFull(currentInspection.fecha);
      currentInspection.fecha_volver = SeveralDates(currentInspection.fecha_volver);
      //Obteniendo hora de inicio
      currentInspection.hora_inicio = props.startTime;
      //Obteniendo hora de fin
      currentInspection.hora_fin = new Date();
      //Si ya tiene "denunciante" por obligacion tiene que ser "colindante"
      if (haveComplaint) {
          currentInspection.insp_den_colin = "colindante";
      }
      //Enviando el numero actual de la cantidad de colindantes de la denuncia
      currentInspection.den_cant_colindantes = denCantColindantes;
      
      //Acomodar todos los campos de INSPECCIONES
      RecordInspection( currentInspection );
      
      //Creando variable para pasar a vectorpoint o a bluepoint para no pasar todos los campos
      let visited = [];
      visited.UNICODE = currentInspection.unicode;
      visited.USER_NAME = currentInspection.user_name;
      visited.FECHA = currentInspection.fecha;
      visited.STATUS_INSPECCION = currentInspection.status_inspeccion;
      visited.INTRA_CHIRIS = currentInspection.intra_chiris;
      visited.PERI_CHIRIS = currentInspection.peri_chiris;
      visited.INSP_DEN_COLIN = currentInspection.insp_den_colin;//Se agrego para poder dibujar el circulo azul
      visited.CARACT_PREDIO = currentInspection.caract_predio; //Se agrego para poder colocarlo en el Popup
      
      //Ver si es una vivienda adicionada
      if(additionalHousing && currentInspection.unicode === additionalHousing.UNICODE){
        /**Variables para guardarlas en la base de datos */
        currentInspection.lat = additionalHousing.LATITUDE;
        currentInspection.lng = additionalHousing.LONGITUDE;
        currentInspection.unicode_base = additionalHousing.UNICODE_BASE;
        
        /**Variables para que funcione adicionada en vectorpoint y puntos azules*/
        visited.LAT = additionalHousing.LATITUDE;
        visited.LNG = additionalHousing.LONGITUDE;
        visited.RISK_COLOR = additionalHousing.color;
        visited.INITIAL_COLOR = additionalHousing.initialColor;
        visited.UNICODE_BASE = additionalHousing.UNICODE_BASE;
        visited.color = additionalHousing.color;
        visited.rociadoText = "Ult. visitaRociada : --:--";
        visited.sprayed = COLOR_BASE_ROCIADO; 
      }
      
      let newHouses = [];
      let numRociado = 0; // variable numero de rociado
      //Realizar el cambio en houses y esto cambiara el mapa
      if (user.USU_APP === "vectorpoint"){
        [newHouses, numRociado] = VectorPoint( houses, visited, user );
      } else if (user.USU_APP === "bluepoint"){
        [newHouses, numRociado] = BluePoint( houses, visited, user );
      } else {
        [newHouses, numRociado] = NoApp( houses, visited, user );
      }
      //Numero de rociado
      currentInspection.roc_numero = numRociado;
      //Guardando registro de inspeccion
      AddInspection(currentInspection);
      //Actualizar viviendas
      UpdateHouses(newHouses);

      //Actualizar la denuncia (en el backend esto se hace mediante procedimiento almacenado)
      if (currentInspection.insp_den_colin !== "NA") {
          denunciations.some( denunciation => {
              if (denunciation.DEN_ID_CUSTOM === den_id_custom){
                  if (insp_den_colin === "denunciante") {
                      denunciation.DEN_UNICODE = currentInspection.unicode;
                      denunciation.DEN_ESTADO = 0;
                  } else //se poner else por que ya se pregunto que sea distinto a NA
                      denunciation.DEN_CANT_COLINDANTES = parseInt(denunciation.DEN_CANT_COLINDANTES)+1;
                  return true;
              } else {
                  return false;
              }
          })
      }
      
      /** Rociado **/
      if (btnSprayed && (currentInspection.status_inspeccion==='C' || currentInspection.status_inspeccion ==='R')) {
          
        //UNICODE
        currentRociado.roc_unicode = currentInspection.unicode;
        //poner usuario
        currentRociado.usu_cuenta = currentInspection.user_name;
        //poner microred
        currentRociado.usu_microred = currentInspection.usu_microred;
        //cambiando el formato de la fecha
        currentRociado.roc_fecha = currentInspection.fecha;
        //Estado de vivienda en rociado
        currentRociado.roc_tratamiento_residual = currentInspection.status_inspeccion
        // cambiando todos los vacios por NA
        PutNA(currentRociado);
        
        let visitaRociado = [];
        visitaRociado.UNICODE = currentRociado.roc_unicode;
        visitaRociado.USU_MICRORED = currentRociado.usu_microred;
        visitaRociado.ROC_FECHA = currentRociado.roc_fecha;
        visitaRociado.ROC_TRATAMIENTO_RESIDUAL = currentRociado.roc_tratamiento_residual;
        visitaRociado.ROC_DESHABITADA_ROCIADA = currentRociado.roc_deshabitada_rociada;
        visitaRociado.ROC_INTRA_CANT_CAPTURADOS = "NA";
        visitaRociado.ROC_PERI_CANT_CAPTURADOS = "NA";
        visitaRociado.ROC_COLINDANTE = currentRociado.roc_colindante;
        visitaRociado.ROC_COLINDANTE_INSP = currentRociado.roc_colindante_insp;

        //Actualizar el mapa
        let [roc_newHouses, roc_numRociado] = RociadoPoint(houses, visitaRociado);
        
        //Numero de rociado
        currentRociado.roc_numero = roc_numRociado;
        //Guardar en base de datos
        AddRociados(currentRociado);

        //Actualizar viviendas
        UpdateHouses(roc_newHouses);
        
        setCurrentRociado(inicioRociado);

        UnPressBtnAddRoc();
      }

      /**
       * Cimex
       */        
      //Consultar si es que se selecciono lo campos adecuado en INSPECCIONES para poder cambiar los datos de CIMEX
      /*if ( currentInspection.status_inspeccion === "entrevista" || currentInspection.status_inspeccion === "inspeccion" ) {
          RecordCimex( currentCimex );
      } else {
          //Nos aseguramos que los campos sean NA, por si los usuarios dejaron informacion almacenado en algun campo y no lo borraron
          setCurrentCimex(initCimex);
      }

      //Poner unicode
      currentCimex.unicode = props.unicode;
      //Poner usuario
      currentCimex.user_name = user.USU_CUENTA.toUpperCase();
      //MIcrored
      currentCimex.usu_microred = user.USU_MICRORED;
      //Obteniendo solo la fecha en campos calendar
      currentCimex.fecha = DateFull(currentInspection.fecha);

      //Guardar en la base de datos de Cimex
      AddCimex(currentCimex);

      //Inicializar cimex
      setCurrentCimex(initCimex);
      */

      //Limpiar formulario de inspecciones
      setCurrentInspection(initInspection);
      //Cerrar modal
      props.ChangeModal();
    };
    
    return (
        <MyModal modal={props.modal} ChangeModal={props.ChangeModal} formTitle={props.formTitle}>
            { alert ? (<Alert className='alert' variant='danger'>{alert.msg}</Alert>) : null }
            <Form onSubmit={handleSubmit(OnSubmit)}>
                { btnSprayed && colindante === true
                    ?
                      <>
                        {/* Preguntar si pertenece a una denuncia */}
                        <Form.Group controlId="relacion_denuncia">
                            <Form.Label>Vivienda relacionada a una denuncia ?</Form.Label>
                            <Col sm={10}>
                                <Form.Check
                                    type="radio"
                                    label="Si"
                                    name="relacion_denuncia"
                                    value="1"
                                    id="si"
                                    checked={relacion_denuncia === "1"}
                                    onChange={OnChange}
                                    ref={register({required: true})}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No"
                                    name="relacion_denuncia"
                                    value="0"
                                    id="no"
                                    checked={relacion_denuncia === "0"}
                                    onChange={OnChange}
                                    ref={register({required: true})}
                                />
                            </Col>
                            {errors.relacion_denuncia && <span className='alert-custom'>*Campo Obligatorio</span>}
                        </Form.Group>
                      </>
                    :null

                }
                { btnInspectionPasive || relacion_denuncia === "1" ?
                    <>
                        {/* den_id_custom */}
                        <Form.Label>Denuncia a la cual pertenece esta inspección*</Form.Label>
                        <Form.Group controlId="den_id_custom">
                            <Form.Control 
                                as="select"
                                name= 'den_id_custom'
                                value= {den_id_custom}
                                onChange= {OnChange}
                                ref={register({ required: true })}
                            >
                                <option value="">Seleccione...</option>
                                {denunciations.map( denunciation => 
                                    <option key={denunciation.DEN_ID_CUSTOM} value={denunciation.DEN_ID_CUSTOM}>{denunciation.DEN_ID_CUSTOM}</option>
                                )}
                            </Form.Control>
                            {errors.den_id_custom && <span className='alert-custom'>*Campo obligatorio</span>}
                        </Form.Group>
                        { den_id_custom !== "" ? (
                            <>
                                {/* INSP_DEN_COLIN */}
                                {denunciations.some( denunciation => {
                                    if (denunciation.DEN_ID_CUSTOM === den_id_custom && denunciation.DEN_UNICODE !== "NA" && denunciation.DEN_UNICODE !== "" ){
                                        denCantColindantes = denunciation.DEN_CANT_COLINDANTES;
                                        haveComplaint = true;
                                        return true;
                                    } else {
                                        return false;
                                    }
                                })}
                                <Form.Group >
                                    <Form.Label>Seleccione que tipo de vivienda es:*</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            disabled = {haveComplaint}
                                            type="radio"
                                            label="Denunciante"
                                            name="insp_den_colin"
                                            value="denunciante"
                                            id="denunciante"
                                            checked={ insp_den_colin=== "denunciante"}
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Colindante"
                                            name="insp_den_colin"
                                            value="colindante"
                                            id="colindante"
                                            checked={ insp_den_colin=== "colindante"}
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                    </Col>
                                    {errors.insp_den_colin && <span className='alert-custom'>*Campo obligatorio</span>}
                                </Form.Group>
                            </>
                            ) : null
                        }
                    </> : null
                }
                
                <Form.Group /*controlId="unicode"*/>
                    <Form.Label >Código de Vivienda</Form.Label>
                    <Form.Control 
                        readOnly
                        type='text'
                        //name='unicode'
                        value={props.unicode}
                        onChange={OnChange}
                    />
                </Form.Group>
                <Form.Group controlId="observaciones">
                    <Form.Check 
                        type="checkbox" 
                        name="observaciones"
                        label="Observaciones sobre unicode"
                        value = {observaciones}
                        onChange={OnChangeCheck}
                    />
                </Form.Group>
                {observaciones? 
                    (<>
                        {/* obs_unicode */}
                        <Form.Group controlId="obs_unicode">
                            <Form.Control 
                                as="select"
                                name= 'obs_unicode'
                                value= {obs_unicode}
                                onChange= {OnChange}
                            >
                                <option value="">Seleccione...</option>
                                <option value="vivienda_con_mas_un_unicode">1) Una vivienda con mas de un unicode</option>
                                <option value="vivienda_sin_unicode_localidad_nueva">2) Vivienda sin unicode (localidad nueva)</option>
                                <option value="vivienda_sin_unicode_manzana_nueva">3) Vivienda sin unicode (en una localidad antigua pero con manzana nueva)</option>
                                <option value="vivienda_un_unicode_pero_con_departamentos">4) Una vivienda con un código pero dividida en departamentos</option>
                                <option value="dos_viviendas_distintas_mismo_unicode">5) Dos viviendas distintas con el mismo unicode</option>
                                <option value="unicode_equivocado">6) Unicode equivocado</option>
                                <option value="unicode_en_mapa_no_en_campo">7) Unicode del mapa que no se encuentra en campo</option>
                                <option value="unicode_en_campo_no_mapa">8) Unicode en campo que no se encuentra en el mapa</option>
                                <option value="otro">9) Otro</option>
                            </Form.Control>
                            {errors.obs_unicode && <span className='alert-custom'>*Campo obligatorio</span>}
                        </Form.Group>
                        {(obs_unicode==='vivienda_sin_unicode_localidad_nueva' || obs_unicode==='vivienda_sin_unicode_manzana_nueva')? (
                            <>
                                {/* OBS_TEXT1 */}
                                <Form.Group controlId="obs_text1">
                                    <Form.Control 
                                        type="text"
                                        name= 'obs_text1'
                                        defaultValue= {obs_text1}
                                        placeholder = "Ingrese la dirección de la vivienda ..."
                                        onChange= {OnChange}
                                        ref={register({ required: true })}
                                    />
                                    {errors.obs_text1 && <span className='alert-custom'>*Campo obligatorio</span>}
                                </Form.Group>
                            </>
                        ):null}
                        {(obs_unicode==='otro')? (
                            <>
                                {/* OBS_TEXT2 */}
                                <Form.Group controlId="obs_text2">
                                    <Form.Control 
                                        type="text"
                                        name= 'obs_text2'
                                        defaultValue= {obs_text2}
                                        placeholder = "Describa su opción ..."
                                        onChange= {OnChange}
                                        ref={register({ required: true })}
                                    />
                                    {errors.obs_text2 && <span className='alert-custom'>*Campo obligatorio</span>}
                                </Form.Group>
                            </>
                        ):null}
                    </>):null
                }
                <hr/>
                {/* FECHA */}
                <Form.Group controlId="fecha">
                    <Form.Label className="fecha-label">Fecha* </Form.Label>
                    <Calendar 
                        showIcon={true} 
                        locale={es} 
                        dateFormat="yy/mm/dd" 
                        name = 'fecha'
                        maxDate={new Date()}
                        value={new Date(fecha)} 
                        onChange={OnChange}
                    />
                </Form.Group>
                {/* INSP_POR_RADIO*/}
                <div className="container-animals">
                  <Form.Group controlId="insp_por_radio_bool">
                      <Form.Check 
                          type="checkbox" 
                          name="insp_por_radio_bool"
                          label="Inspeccion producida por radio"
                          value = {insp_por_radio_bool}
                          onChange={OnChangeCheck}
                      />
                  </Form.Group>
                  {insp_por_radio_bool &&
                    <>
                      <Form.Label>Vivienda positiva que generó esta inspección*</Form.Label>
                      <Form.Group controlId="insp_por_radio">
                          <Form.Control 
                            as="select"
                            name= 'insp_por_radio'
                            value= {insp_por_radio}
                            onChange= {OnChange}
                            ref={register({ required: true })}
                          >
                            <option value="">Seleccione...</option>
                            {inspections.map( inspection => {
                              console.log(inspection);
                              if (inspection.INTRA_CHIRIS==="1" || inspection.INTRA_RASTROS==="1" || inspection.PERI_CHIRIS==="1" || inspection.PERI_RASTROS==="1" ) {
                                return (<option key={inspection.UNICODE} value={inspection.UNICODE}>{inspection.UNICODE}</option>);
                              }
                              return true;
                            })}
                          </Form.Control>
                          {errors.insp_por_radio && <span className='alert-custom'>*Campo obligatorio</span>}
                      </Form.Group>
                    </>
                  }
                </div>
                {/* CARACT_PREDIO */}
                <Form.Group controlId="caract_predio">
                    <Form.Label >Características del predio*</Form.Label>
                    <Form.Control 
                        as="select"
                        name= 'caract_predio'
                        value= {caract_predio}
                        onChange= {OnChange}
                    >
                        <option value="DES">Deshabitada</option>
                        <option value="casa_regular">Casa regular</option>
                        <option value="LV">Lote vacío</option>
                        <option value="LP">Local público</option>
                    </Form.Control>
                </Form.Group>
                {(caract_predio==='LP')? (
                    <>
                        {/* TIPO_LP */}
                        <Form.Group controlId="tipo_lp">
                            <Form.Label>Tipo local público*</Form.Label>
                            <Form.Control 
                                type="text"
                                name= 'tipo_lp'
                                defaultValue= {tipo_lp}
                                onChange= {OnChange}
                                ref={register({ required: true })}
                            />
                            {errors.tipo_lp && <span className='alert-custom'>*Campo obligatorio</span>}
                        </Form.Group>
                    </>
                ):null}
                {(caract_predio==='casa_regular' || caract_predio==='LP' || caract_predio==='DES')? (
                    <>
                        {/* STATUS_INSPECCION */}
                        <Form.Group controlId="status_inspeccion">
                            <Form.Label >Estado de la Inspeccíon*</Form.Label>
                            <Form.Control 
                                as="select"
                                name= 'status_inspeccion'
                                value= {status_inspeccion}
                                onChange= {OnChange}
                            >
                                <option value="C">Cerrada</option>
                                <option value="R">Renuente</option>
                                <option value="V">Volver</option>
                                <option value="entrevista">Entrevista</option>
                                <option value="inspeccion">Inspección</option>
                            </Form.Control>
                        </Form.Group>
                        {(status_inspeccion === 'entrevista')? (
                            <>
                                {/* ENTREVISTA */}
                                <Form.Group>
                                    <Form.Label>Entrevista</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="radio"
                                            label="Cree que no tiene"
                                            name="entrevista"
                                            value="cree_no_tiene"
                                            id="cree_no_tiene"
                                            checked={ entrevista=== "cree_no_tiene"}
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Cree que si tiene"
                                            name="entrevista"
                                            value="cree_si_tiene"
                                            id="cree_si_tiene"
                                            checked={ entrevista=== "cree_si_tiene"}
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No sabe"
                                            name="entrevista"
                                            value="no_sabe"
                                            id="no_sabe"
                                            checked={ entrevista=== "no_sabe"}
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                    </Col>
                                    {errors.entrevista && <span className='alert-custom'>*Campo obligatorio</span>}
                                </Form.Group>
                            </>
                        ):null}
                        {(status_inspeccion === 'V')? (
                            <>
                                {/* MOTIVO_VOLVER */}
                                <Form.Group controlId="motivo_volver">
                                    <Form.Label>Motivo*</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name= 'motivo_volver'
                                        defaultValue= {motivo_volver==="NA"?"":motivo_volver}
                                        onChange= {OnChange}
                                        placeholder = "Escribe aqui el motivo ..."
                                        ref={register({ required: true })}
                                    />
                                    {errors.motivo_volver && <span className='alert-custom'>*Campo obligatorio</span>}
                                </Form.Group>
                                {/* FECHA_VOLVER*/}
                                <Form.Group controlId="fecha_volver">
                                    <Form.Label className="fecha-label">Fecha probable para volver: </Form.Label>
                                    <Calendar 
                                        minDate = { new Date() }
                                        maxDateCount = {3}
                                        showIcon={true} 
                                        locale={es} 
                                        dateFormat="yy/mm/dd" 
                                        value={fecha_volver==="NA"?"":fecha_volver}
                                        name= 'fecha_volver'
                                        onChange={ OnChange } 
                                        selectionMode="multiple" 
                                        readOnlyInput={true} 
                                    />
                                </Form.Group>
                            </>
                        ):null}
                        {(status_inspeccion === 'R')? (
                            <>
                                {/* RENUENTE */}
                                <Form.Group controlId="renuente">
                                    <Form.Control 
                                        as="select"
                                        name= 'renuente'
                                        value= {renuente}
                                        onChange= {OnChange}
                                        ref={register({ required: true })}
                                    >
                                        <option value="">Seleccione...</option>
                                        <option value="R1">R1) No tiene tiempo trabaja</option>
                                        <option value="R2">R2) Desconfianza</option>
                                        <option value="R3">R3) Casa limpia</option>
                                        <option value="R4">R4) Inquilinos</option>
                                        <option value="R5">R5) No da ningun motivo</option>
                                        <option value="R6">R6) Otro</option>
                                    </Form.Control>
                                    {errors.renuente && <span className='alert-custom'>*Campo obligatorio</span>}
                                </Form.Group>
                                {(renuente === 'R6')? (
                                    <>
                                        {/* RENUENTE_OTRO */}
                                        <Form.Group controlId="renuente_otro">
                                            <Form.Control 
                                                type="text"
                                                name= 'renuente_otro'
                                                defaultValue= {renuente_otro}
                                                onChange= {OnChange}
                                                placeholder = "Describa su opción ..."
                                                ref={register({ required: true })}
                                            />
                                            {errors.renuente_otro && <span className='alert-custom'>*Campo obligatorio</span>}
                                        </Form.Group>
                                    </>
                                ):null}
                            </>
                        ):null}
                        {(status_inspeccion === 'inspeccion')? (
                        <>
                            {/* INSP_HABITANTE_TELEFONO */}
                            <Form.Group controlId="insp_habitante_telefono">
                                <Form.Label>Teléfono del Habitante</Form.Label>
                                <Form.Control 
                                    type='number'
                                    name='insp_habitante_telefono'
                                    defaultValue={insp_habitante_telefono}
                                    onChange={OnChange}
                                    ref={register({ maxLength: 9 })}
                                />
                                {errors.insp_habitante_telefono?.type === 'maxLength' && <span className='alert-custom'>*Maximo 9 numeros</span>}
                            </Form.Group>
                            <hr/>
                            <div className="table-check">
                                <Col>
                                    <Row></Row>
                                    <Row>INTRA</Row>
                                    <Row className="checkbox-end">PERI</Row>
                                </Col>
                                <Col>
                                    <Row>Lugar inspección*</Row>
                                    {/* DEN_TIPO */}
                                    <Form.Group >
                                        <Col sm={10}>
                                            <Form.Check
                                                type="checkbox"
                                                name="multiplecheckbox"
                                                value="intra_inspeccion"
                                                id="intra_inspeccion"
                                                onChange= {OnChangeCheckMultiple}
                                                ref={register({ required: true })}
                                            />
                                            <Form.Check className="checkbox-end" 
                                                type="checkbox"
                                                name="multiplecheckbox"
                                                value="peri_inspeccion"
                                                id="peri_inspeccion"
                                                onChange= {OnChangeCheckMultiple}
                                                ref={register({ required: true })}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Row className="title-label">Halló Chiris</Row>
                                    {/* INTRA_CHIRIS */}
                                    <Form.Group controlId="intra_chiris">
                                        <Form.Check 
                                            disabled={!intra_inspeccion}
                                            type="checkbox" 
                                            name="intra_chiris"
                                            value= {intra_chiris}
                                            onChange={OnChangeCheck}
                                        />
                                    </Form.Group>
                                    {/* PERI_CHIRIS */}
                                    <Form.Group controlId="peri_chiris">
                                        <Form.Check 
                                            disabled={!peri_inspeccion}
                                            type="checkbox" 
                                            name="peri_chiris"
                                            value={peri_chiris}
                                            onChange={OnChangeCheck}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Row className="title-label">Halló Rastros</Row>
                                    {/* INTRA_RASTROS */}
                                    <Form.Group controlId="intra_rastros">
                                        <Form.Check 
                                            disabled={!intra_inspeccion}
                                            type="checkbox" 
                                            name="intra_rastros"
                                            value={intra_rastros}
                                            onChange={OnChangeCheck}
                                        />
                                    </Form.Group>
                                    {/* RASTROS_PERI */}
                                    <Form.Group controlId="peri_rastros">
                                        <Form.Check 
                                            disabled={!peri_inspeccion}
                                            type="checkbox" 
                                            name="peri_rastros"
                                            value={peri_rastros}
                                            onChange={OnChangeCheck}
                                        />
                                    </Form.Group>
                                </Col>                                
                            </div>
                            {errors.multiplecheckbox && <span className='alert-custom'>*Lugar de inspección es obligatorio</span>}
                            <hr/>
                            {/* PERSONAS_PREDIO*/}
                            <Form.Group controlId="personas_predio">
                                <Form.Label>Cuántas personas viven en el predio?*</Form.Label>
                                <Form.Control 
                                    type='number'
                                    name='personas_predio'
                                    defaultValue={personas_predio}
                                    onChange={OnChange}
                                    ref={register({ required: true, min: 0, maxLength: 2 })}
                                />
                                {errors.personas_predio?.type === 'required' && <span className='alert-custom'>*Campo obligatorio</span>}
                                {errors.personas_predio?.type === 'min' && <span className='alert-custom'>*No puede haber valor negativo</span>}
                                {errors.personas_predio?.type === 'maxLength' && <span className='alert-custom'>*Máximo 99 personas</span>}
                            </Form.Group>
                            {/* ANIMALES */}
                            <div className="animales-opt">Qué animales hay?
                                <Row>
                                    <Col>
                                        {/* PERROS */}
                                        <Form.Group controlId="perros">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="perros"
                                                onChange={OnChangeCheck}
                                            />
                                            <Form.Label>Perros</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    {(perros === true)? (
                                        <Col>
                                            {/* CANT_PERROS */}
                                            <Form.Group controlId="cant_perros">
                                                <Form.Control 
                                                    type='number'
                                                    name='cant_perros'
                                                    defaultValue={cant_perros}
                                                    onChange={OnChange}
                                                    ref={register({ required: true, min: 1, maxLength: 2 })}
                                                />
                                                {errors.cant_perros?.type === 'required' && <span className='alert-custom'>*Campo obligatorio</span>}
                                                {errors.cant_perros?.type === 'min' && <span className='alert-custom'>*No puede haber valor menor que 1</span>}
                                                {errors.cant_perros?.type === 'maxLength' && <span className='alert-custom'>*Máximo 99 personas</span>}
                                            </Form.Group>
                                        </Col>
                                    ):null}
                                </Row>
                                <Row>
                                    <Col>
                                        {/* GATOS */}
                                        <Form.Group controlId="gatos">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="gatos"
                                                onChange={OnChangeCheck}
                                            />
                                            <Form.Label>Gatos</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    {(gatos === true)? (
                                        <Col>
                                            {/* CANT_GATOS */}
                                            <Form.Group controlId="cant_gatos">
                                                <Form.Control 
                                                    type='number'
                                                    name='cant_gatos'
                                                    defaultValue={cant_gatos}
                                                    onChange={OnChange}
                                                    ref={register({ required: true, min: 1, maxLength: 2 })}
                                                />
                                                {errors.cant_gatos?.type === 'required' && <span className='alert-custom'>*Campo obligatorio</span>}
                                                {errors.cant_gatos?.type === 'min' && <span className='alert-custom'>*No puede haber valor menor que 1</span>}
                                                {errors.cant_gatos?.type === 'maxLength' && <span className='alert-custom'>*Máximo 99 personas</span>}
                                            </Form.Group>
                                        </Col>
                                    ):null}
                                </Row>
                                <Row>
                                    <Col>
                                        {/* AVES_CORRAL */}
                                        <Form.Group controlId="aves_corral">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="aves_corral"
                                                onChange={OnChangeCheck}
                                            />
                                            <Form.Label>Aves Corral</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    {(aves_corral === true)? (
                                        <Col>
                                            {/* CANT_AVES_CORRAL */}
                                            <Form.Group controlId="cant_aves_corral">
                                                <Form.Control 
                                                    type='number'
                                                    name='cant_aves_corral'
                                                    defaultValue={cant_aves_corral}
                                                    onChange={OnChange}
                                                    ref={register({ required: true, min: 1, maxLength: 2 })}
                                                />
                                                {errors.cant_aves_corral?.type === 'required' && <span className='alert-custom'>*Campo obligatorio</span>}
                                                {errors.cant_aves_corral?.type === 'min' && <span className='alert-custom'>*No puede haber valor menor que 1</span>}
                                                {errors.cant_aves_corral?.type === 'maxLength' && <span className='alert-custom'>*Máximo 99 personas</span>}
                                            </Form.Group>
                                        </Col>
                                    ):null}
                                </Row>
                                <Row>
                                    <Col>
                                        {/* CUYES */}
                                        <Form.Group controlId="cuyes">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cuyes"
                                                onChange={OnChangeCheck}
                                            />
                                            <Form.Label>Cuyes</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    {(cuyes === true)? (
                                        <Col>
                                            {/* CANT_CUYES */}
                                            <Form.Group controlId="cant_cuyes">
                                                <Form.Control 
                                                    type='number'
                                                    name='cant_cuyes'
                                                    defaultValue={cant_cuyes}
                                                    onChange={OnChange}
                                                    ref={register({ required: true, min: 1, maxLength: 2 })}
                                                />
                                                {errors.cant_cuyes?.type === 'required' && <span className='alert-custom'>*Campo obligatorio</span>}
                                                {errors.cant_cuyes?.type === 'min' && <span className='alert-custom'>*No puede haber valor menor que 1</span>}
                                                {errors.cant_cuyes?.type === 'maxLength' && <span className='alert-custom'>*Máximo 99 personas</span>}
                                            </Form.Group>
                                        </Col>
                                    ):null}
                                </Row>
                                <Row>
                                    <Col>
                                        {/* CONEJOS */}
                                        <Form.Group controlId="conejos">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="conejos"
                                                onChange={OnChangeCheck}
                                            />
                                            <Form.Label>Conejos</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    {(conejos === true)? (
                                        <Col>
                                            {/* CANT_CONEJOS */}
                                            <Form.Group controlId="cant_conejos">
                                                <Form.Control 
                                                    type='number'
                                                    name='cant_conejos'
                                                    defaultValue={cant_conejos}
                                                    onChange={OnChange}
                                                    ref={register({ required: true, min: 1, maxLength: 2 })}
                                                />
                                                {errors.cant_conejos?.type === 'required' && <span className='alert-custom'>*Campo obligatorio</span>}
                                                {errors.cant_conejos?.type === 'min' && <span className='alert-custom'>*No puede haber valor menor que 1</span>}
                                                {errors.cant_conejos?.type === 'maxLength' && <span className='alert-custom'>*Máximo 99 personas</span>}
                                            </Form.Group>
                                        </Col>
                                    ):null}
                                </Row>
                                <Row>
                                    <Col>
                                        {/* OTROS */}
                                        <Form.Group controlId="otros">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="otros"
                                                onChange={OnChangeCheck}
                                            />
                                            <Form.Label>Otros</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    {(otros === true)? (
                                        <Col>
                                            {/* TEXT_OTROS */}
                                            <Form.Group controlId="text_otros">
                                                <Form.Control 
                                                    type="text"
                                                    name= 'text_otros'
                                                    defaultValue= {text_otros}
                                                    onChange= {OnChange}
                                                    placeholder = "Indique otro ..."
                                                    ref={register({ required: true })}
                                                />
                                                {errors.text_otros && <span className='alert-custom'>*Campo obligatorio</span>}
                                            </Form.Group>
                                        </Col>
                                    ):null}
                                </Row>
                                <Row>
                                    <Col></Col>
                                    {(otros === true)? (
                                        <Col>
                                            {/* CANT_OTROS */}
                                            <Form.Group controlId="cant_otros">
                                                <Form.Control 
                                                    type='number'
                                                    name='cant_otros'
                                                    defaultValue={cant_otros}
                                                    onChange={OnChange}
                                                    ref={register({ required: true, min: 1, maxLength: 2 })}
                                                />
                                                {errors.cant_otros?.type === 'required' && <span className='alert-custom'>*Campo obligatorio</span>}
                                                {errors.cant_otros?.type === 'min' && <span className='alert-custom'>*No puede haber valor menor que 1</span>}
                                                {errors.cant_otros?.type === 'maxLength' && <span className='alert-custom'>*Máximo 99 personas</span>}
                                            </Form.Group>
                                        </Col>
                                    ):null}
                                </Row>
                            </div>
                        </>
                        ):null}
                    </>
                ):null}

                {//ROCIADO
                    btnSprayed && (status_inspeccion === 'R' || status_inspeccion === 'C') &&
                    <>
                        <hr/>
                        <h5>Rociado</h5>
                        <div>Visita en Rociado: {status_inspeccion}</div>
                        <div>Vivienda colindante en rociado</div>
                        {/* roc_colindante_insp */}
                        <br/>
                        <Form.Group controlId="roc_colindante_insp">
                            <Form.Label>UNICODE de Vivienda Foco*</Form.Label>
                            <Form.Control
                                as="select"
                                name="roc_colindante_insp"
                                value={roc_colindante_insp}
                                onChange={OnChange}
                                ref={register({ required: true })}
                            >
                            <option value="">Seleccione Vivienda Original</option>
                                {inspections.map(vivienda =>{
                                    if(vivienda.STATUS_INSPECCION === "inspeccion"){
                                        return <option key={vivienda.UNICODE} value={vivienda.UNICODE}>{vivienda.UNICODE}</option>
                                    }
                                    return true;
                                })}
                            </Form.Control>
                            {errors.roc_colindante_insp && <span className='alert-custom'>*Campo obligatorio</span>}
                        </Form.Group>
                    </>
                }
                {/*
                <hr/>
                {(status_inspeccion === 'entrevista' || status_inspeccion === 'inspeccion') && 
                    <>
                        {/*******  FORMULARIO CIMEX *********/}
                {/*        <h5>CHINCHES DE CAMA</h5>
                        {/* CIMEX_ALGUIEN_PICADO_CASA_ULTIMO_ANIO */}
                {/*        <Form.Group>
                            <Form.Label>1.- En el último año, ¿Algún miembro del hogar ha sido picado por insectos al interior de la vivienda?</Form.Label>
                            <Col sm={10}>
                                <Form.Check
                                    type="radio"
                                    label="Sí"
                                    name="cimex_alguien_picado_casa_ultimo_anio"
                                    value="1"
                                    id="cimex_alguien_picado_casa_ultimo_anio_si"
                                    onChange= {OnChange}
                                    ref={register({ required: true })}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No"
                                    name="cimex_alguien_picado_casa_ultimo_anio"
                                    value="0"
                                    id="cimex_alguien_picado_casa_ultimo_anio_no"
                                    onChange= {OnChange}
                                    ref={register({ required: true })}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No sabe"
                                    name="cimex_alguien_picado_casa_ultimo_anio"
                                    value="NS"
                                    id="cimex_alguien_picado_casa_ultimo_anio_ns"
                                    onChange= {OnChange}
                                    ref={register({ required: true })}
                                />
                            </Col>
                            {errors.cimex_alguien_picado_casa_ultimo_anio && <span className='alert-custom'>*Campo obligatorio</span>}
                        </Form.Group>
                        {cimex_alguien_picado_casa_ultimo_anio === '1' && 
                            <>
                                {/* cimex_sabe_insecto_pico */}
                {/*                <Form.Group>
                                    <Form.Label>1.1.- ¿Sabe que insecto le pico?</Form.Label>
                                    <Col sm={10}>
                                        <Form.Group className="wrapper-field-check" controlId="chinches_cama">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_chinches_cama"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Chinches de Cama</Form.Label>    
                                        </Form.Group>
                                        <Form.Group className="wrapper-field-check" controlId="chirimachas">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_chirimachas"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Chirimachas</Form.Label>    
                                        </Form.Group>
                                        <Form.Group className="wrapper-field-check" controlId="mosquitos">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_mosquitos"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Mosquitos</Form.Label>    
                                        </Form.Group>
                                        <Form.Group className="wrapper-field-check" controlId="zancudos">
                                            <Form.Check 
                                                type="checkbox"
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_zancudos"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Zancudos</Form.Label>    
                                        </Form.Group>
                                        <Form.Group className="wrapper-field-check" controlId="aranias">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_aranias"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Arañas</Form.Label>    
                                        </Form.Group>
                                        <Form.Group className="wrapper-field-check" controlId="pulgas">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_pulgas"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Pulgas</Form.Label>    
                                        </Form.Group>
                                        <Form.Group className="wrapper-field-check" controlId="piojos">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_piojos"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Piojos</Form.Label>    
                                        </Form.Group>
                                        <Form.Group className="wrapper-field-check" controlId="garrapatas">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_garrapatas"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Garrapatas</Form.Label>    
                                        </Form.Group>
                                        <Form.Group className="wrapper-field-check" controlId="pique">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_pique"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Pique</Form.Label>    
                                        </Form.Group>
                                        <Form.Group className="wrapper-field-check" controlId="hitas">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_hitas"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Hitas</Form.Label>    
                                        </Form.Group>
                                        <Form.Group className="wrapper-field-check" controlId="alacranes">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_alacranes"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Alacranes</Form.Label>    
                                        </Form.Group>                  
                                        <Form.Group className="wrapper-field-check" controlId="otros">
                                            <Form.Check 
                                                type="checkbox" 
                                                name="cimex_sabe_insecto_pico"
                                                value="cimex_otros"
                                                onChange={OnChangeCheckMultiple}
                                                className="field-check"
                                                ref={register({ required: true })}
                                            />
                                            <Form.Label>Otros</Form.Label>    
                                        </Form.Group>
                                    </Col> 
                                    {errors.cimex_sabe_insecto_pico && <span className='alert-custom'>*Es obligatorio seleccionar al menos una opcion</span>}
                                </Form.Group>
                            </>
                        }
                        {/*cimex_conoce*/}
                {/*        <Form.Group>
                            <Form.Label>2.- ¿Conoce usted este insecto? (Se le enseña la placa con ciclo de vida de chinches. Después de obtener respuesta del poblador, indicar que son “Chinches de cama” si el poblador no los conoce.</Form.Label>
                            <Col sm={10}>
                                <Form.Check
                                    type="radio"
                                    label="Sí"
                                    name="cimex_conoce"
                                    value="1"
                                    id="cimex_conoce_si"
                                    onChange= {OnChange}
                                    ref={register({ required: true })}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No"
                                    name="cimex_conoce"
                                    value="0"
                                    id="cimex_conoce_no"
                                    onChange= {OnChange}
                                    ref={register({ required: true })}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No sabe"
                                    name="cimex_conoce"
                                    value="NS"
                                    id="cimex_conoce_ns"
                                    onChange= {OnChange}
                                    ref={register({ required: true })}
                                />
                            </Col>
                            {errors.cimex_conoce && <span className='alert-custom'>*Campo obligatorio</span>}
                        </Form.Group>
                        {/*cimex_en_su_vivienda*/}
                {/*        <Form.Group>
                            <Form.Label>3.- En el último año, ¿Ha visto chinches de cama en su vivienda?</Form.Label>
                            <Col sm={10}>
                                <Form.Check
                                    type="radio"
                                    label="Sí"
                                    name="cimex_en_su_vivienda"
                                    value="1"
                                    id="cimex_en_su_vivienda_si"
                                    onChange= {OnChange}
                                    ref={register({ required: true })}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No"
                                    name="cimex_en_su_vivienda"
                                    value="0"
                                    id="cimex_en_su_vivienda_no"
                                    onChange= {OnChange}
                                    ref={register({ required: true })}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No sabe"
                                    name="cimex_en_su_vivienda"
                                    value="NS"
                                    id="cimex_en_su_vivienda_ns"
                                    onChange= {OnChange}
                                    ref={register({ required: true })}
                                />
                            </Col>
                            {errors.cimex_en_su_vivienda && <span className='alert-custom'>*Campo obligatorio</span>}
                        </Form.Group>
                        {cimex_en_su_vivienda === '1' && 
                            <>
                                {/* cimex_inspeccion_gratuita */}
                {/*                <Form.Group>
                                    <Form.Label>3.1.- ¿Desea inspección gratuita de chinches?</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="radio"
                                            label="Sí"
                                            name="cimex_inspeccion_gratuita"
                                            value="1"
                                            id="cimex_inspeccion_gratuita_si"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="cimex_inspeccion_gratuita"
                                            value="0"
                                            id="cimex_inspeccion_gratuita_no"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                    </Col>
                                    {errors.cimex_inspeccion_gratuita && <span className='alert-custom'>*Campo obligatorio</span>}
                                </Form.Group>
                            </>
                        }
                        {cimex_inspeccion_gratuita === '1' && 
                            <>
                                {/* cimex_fecha_tentativa_inspeccion */}
                {/*                <Form.Group controlId="cimex_fecha_tentativa_inspeccion">
                                    <Form.Label className="fecha-label">3.1.1.- Fecha tentativa para inspección</Form.Label>
                                    <Calendar 
                                        showIcon={true} 
                                        locale={es} 
                                        minDate = { ( new Date()).addDays(1) }
                                        dateFormat="yy/mm/dd" 
                                        name = 'cimex_fecha_tentativa_inspeccion'
                                        value={new Date(cimex_fecha_tentativa_inspeccion)} 
                                        onChange={OnChange}
                                    />
                                </Form.Group>
                                {/* cimex_hora_tentativa_inspeccion */}
                {/*                <Form.Group>
                                    <Form.Label>3.1.2.- Prefiere alguna hora o cualquier hora</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="radio"
                                            label="Cualquier hora"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="cualquier_hora"
                                            id="cualquier_hora"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label=" 7:00am"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="7:00am"
                                            id="cimex_hora_tentativa_inspeccion_7am"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label=" 8:00am"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="8:00am"
                                            id="cimex_hora_tentativa_inspeccion_8am"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label=" 9:00am"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="9:00am"
                                            id="cimex_hora_tentativa_inspeccion_9am"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label=" 10:00am"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="10:00am"
                                            id="cimex_hora_tentativa_inspeccion_10am"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="11:00am"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="11:00am"
                                            id="cimex_hora_tentativa_inspeccion_11am"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label=" 12:00m"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="12:00m"
                                            id="cimex_hora_tentativa_inspeccion_12m"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label=" 1:00pm"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="1:00pm"
                                            id="cimex_hora_tentativa_inspeccion_1pm"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label=" 2:00pm"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="2:00pm"
                                            id="cimex_hora_tentativa_inspeccion_2pm"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label=" 3:00pm"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="3:00pm"
                                            id="cimex_hora_tentativa_inspeccion_3pm"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label=" 4:00pm"
                                            name="cimex_hora_tentativa_inspeccion"
                                            value="4:00pm"
                                            id="cimex_hora_tentativa_inspeccion_4pm"
                                            onChange= {OnChange}
                                            ref={register({ required: true })}
                                        />
                                    </Col>
                                    {errors.cimex_hora_tentativa_inspeccion && <span className='alert-custom'>*Campo obligatorio</span>}
                                </Form.Group>
                            </>
                        }
                    </>
                }
                */}
                <Button type='submit' id="submit-inspection">Guardar</Button> 
            </Form>
        </MyModal>
    );
}

export default FormInsp;
