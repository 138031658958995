import React, {useContext} from "react";
import { Button } from 'react-bootstrap';
import CsvContext from "../../context/csv/CsvContext";

import { COLOR_BASE_ROCIADO, INSPECTION_TEXT, SPRAYED_TEXT } from "../../resources";

const AdditionalHousing = (props) => {
    //Obtener viviendas
    const CsvsContext = useContext(CsvContext);
    const { houses, SetAdditional } = CsvsContext;

    const HandleAdditional = () => {
        let unicode = props.valUnicode;
        let prevAdditional = []; //Almacenar todas las adicionadas de esa vivienda
        let house = {};
        
        houses.forEach(element => {
            if (element.additional === 1) {
                //Unicode auxiliar
                let auxUnicode = element.UNICODE.substring(0,element.UNICODE.length-1);
                if (auxUnicode === unicode) {
                    prevAdditional.push(element.UNICODE);
                }
            } else if (element.UNICODE === unicode){
                //Construyendo vivienda adicional con el unicode base
                house = Object.assign({}, element);
                house.inspectionText = INSPECTION_TEXT;
                house.rociadoText = SPRAYED_TEXT;
                house.semaphore = true;
                house.initialColor = house.color;
                house.sprayed = COLOR_BASE_ROCIADO;
                house.UNICODE_BASE = house.UNICODE;
                house.additional = 1;//vivienda adicional
                delete house.STATUS_INSPECCION;
                delete house.INSP_DEN_COLIN;
                delete house.STATUS_ROCIADO;
                delete house.group_num;
                delete house.colorBorde;
                delete house.UNICODE;
            }
        });
        house.prevAdditional = prevAdditional;
        SetAdditional(house);
        
    }

    return(
        <Button className="custom-button btn-additional" onClick={HandleAdditional}>Adicional</Button>
    );
}

export default AdditionalHousing;