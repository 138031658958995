import React, { useContext, useState, useEffect } from "react";
import { Popup, Circle, Marker } from "react-leaflet";
import L from "leaflet";
import { Button } from 'react-bootstrap';

import AuthenticationContext from "../../context/authentication/AuthenticationContext";
import CsvContext from "../../context/csv/CsvContext";
import FormInsp from "../Activities/Inspections/FormInsp";
import RociadoContext from "../../context/rociados/RociadoContext";
import FormRociado from "../../components/Activities/Rociados/FormRociado";
import FormPublicityAgent  from "../Activities/Inspections/FormPublicityAgent";
import AdditionalHousing from "./AdditionalHousing";
import FormProgramRoc from "../Activities/Rociados/FormProgramRoc";
import { COLOR_INSPECCION_POSITIVA, COLOR_INSPECCION_NEGATIVA, COLOR_BASE, 
      COLOR_PRINCIPAL_BP, COLOR_ROCIADO_POSITIVO, COLOR_SEGUNDO_ROCIADO_POSITIVO, COLOR_PUBLICIDAD, 
      COLOR_AGENTE } from "../../resources";

const CircleHouses = () => {
  
    const AuthenticationsContext = useContext(AuthenticationContext);
    const { user, btnInspectionPasive, btnInspectionActive, btnSprayed } = AuthenticationsContext;
    //Obtener viviendas
    const CsvsContext = useContext(CsvContext);
    let { houses, additionalHousing } = CsvsContext;
    //Obtener el Rociado
    const RociadosContext = useContext(RociadoContext);
    const { PressBtnAddRoc,
            UnPressBtnNoRoc} = RociadosContext;

    //Modal
    const [modal, setModal] = useState(false);
    //Titulo del formulario
    const [formTitle, setFormTitle] = useState(null);
    //Unicode
    const [unicode, setUnicode] = useState(null);
    //Color en la visita
    const [riskColor, setRiskColor] = useState(null);
    //Color inicial
    const [initialColor, setInitialColor] = useState(null);
    //Cuadra
    const [codeLocBlock, setCodeLocBlock] = useState(null);
    //Hora de inicio
    const [startTime, setStartTime] = useState(new Date());
    //Abrir formulario de Rociado Programado
    const [boolProgramRoc, setBoolProgramRoc] = useState(false);
    //Abrir formulario de Rociado
    const [boolRociado, setBoolRociado] = useState(false);
    //Abrir formulario de Publicidad
    const [boolPublicity, setBoolPublicity] = useState(false);    
    
    const HandleAdd = ( valUnicode, valColor, valInitialColor, valCodeLocBlock ) => {
      setBoolProgramRoc(false);
      setBoolRociado(false);
      setBoolPublicity(false);
      setUnicode(valUnicode);
      setRiskColor(valColor);
      setInitialColor(valInitialColor);
      setCodeLocBlock(valCodeLocBlock);
      setFormTitle("Ingresar registro de inspecciones");
      ChangeModal();
      setStartTime(new Date());
    }

    const HandleAddRoc = (valUnicode) => {
      UnPressBtnNoRoc();
      setBoolProgramRoc(false);
      setBoolRociado(true);
      setBoolPublicity(false);
      setUnicode(valUnicode);
      setFormTitle("Ingresar registro de rociados");
      ChangeModal();
      PressBtnAddRoc();
    }
    const HandlePublicity = (valUnicode) => {
      setBoolProgramRoc(false);
      setBoolRociado(false);
      setBoolPublicity(true);
      setUnicode(valUnicode);
      setFormTitle("Publicidad");
      ChangeModal();
    }

    const ChangeModal = () => {
      setModal(!modal);
    }
    
    //Icono
    const customMarker = L.icon({ 
      iconUrl: require('../../img/cruznegra.png'), 
      iconSize: new L.Point(20, 20),//iconSize: [30, 40]
    });

    useEffect(() => {
      //Habilitar formulario de inspecciones cuando se guarda una adicional
      if (!additionalHousing.form && additionalHousing.UNICODE) {
        HandleAdd(additionalHousing.UNICODE, additionalHousing.color, additionalHousing.initialColor, additionalHousing.codeLocBlock);
        //Para que solo ingrese una vez aqui
        additionalHousing.form = 1;
      }
      // eslint-disable-next-line
    }, [additionalHousing.UNICODE]);

    return (
      <>
        {houses.map( (element) => (
          element.color !== COLOR_INSPECCION_POSITIVA
            ? <Circle
                key = {element.UNICODE}
                center = {[parseFloat(element.LATITUDE),parseFloat(element.LONGITUDE)]}
                fillColor = { 
                              (element.color === COLOR_INSPECCION_NEGATIVA || btnInspectionActive) 
                                ? element.color
                                : COLOR_BASE
                            }
                radius = {btnInspectionActive && element.color === COLOR_PRINCIPAL_BP ? 8:6}
                color = "black"
                weight = {0.2}
                fillOpacity = {1}
                pane={"shadowPane"}//Capa superior
              >
                { //Contorno amarillo en vivienda que tienen publicidad
                  user.USU_APP!=="bluepoint" && btnInspectionPasive &&  element.colorBorde===COLOR_PUBLICIDAD
                    &&  <Circle
                          key = {element.UNICODE+"_1"}
                          center = {[parseFloat(element.LATITUDE),parseFloat(element.LONGITUDE)]}
                          fillColor = {element.colorBorde}
                          radius = {10}
                          color = "black"
                          weight = {0.2}
                          fillOpacity = {1}
                        />
                }
                { //Contorno azul en vivienda que tienen un agente
                  user.USU_APP==="vectorpoint" && btnInspectionPasive &&  element.colorBorde===COLOR_AGENTE
                    &&  <Circle
                          key = {element.UNICODE+"_2"}
                          center = {[parseFloat(element.LATITUDE),parseFloat(element.LONGITUDE)]}
                          fillColor = {element.colorBorde}
                          radius = {10}
                          color = "black"
                          weight = {0.2}
                          fillOpacity = {1}
                        />
                }
                {
                  //Contorno rojo en vivienda de QUEQUEÑA que mike paso informacion
                  user.USU_APP==="vectorpoint" && btnInspectionActive &&  element.colorBorde==="red" && element.color!==COLOR_INSPECCION_NEGATIVA 
                    &&  <Circle
                          key = {element.UNICODE}
                          center = {[parseFloat(element.LATITUDE),parseFloat(element.LONGITUDE)]}
                          fillColor = {element.colorBorde}
                          radius = {10}
                          color = "black"
                          weight = {0.2}
                          fillOpacity = {1}
                        />
                }
                { //ROCIADO
                  (btnSprayed || element.sprayed === COLOR_ROCIADO_POSITIVO || element.sprayed === COLOR_SEGUNDO_ROCIADO_POSITIVO)
                    &&  <Circle
                          key = {element.UNICODE}
                          center = {[parseFloat(element.LATITUDE),parseFloat(element.LONGITUDE)]}
                          fillColor = {element.sprayed}
                          radius = {8}
                          color = "transparent"
                          weight = {0.2}
                          fillOpacity = {1}
                        />
                }
                <Popup>
                  <b style={{fontSize:14}}>{element.UNICODE}</b>
                  <hr style={{margin:5}}/>
                  {element.inspectionText}
                  <hr style={{margin:3}}/>
                  {//Rociado
                    element.rociadoText
                  }
                  {//Boton Inspeccion 
                    <div>
                      <Button className="custom-button" onClick={()=>HandleAdd(element.UNICODE, element.color, element.initialColor, element.codeLocBlock)}>Inspeccionar</Button>
                    </div>
                  }
                  { //Boton Rociado
                    btnSprayed
                     &&
                        <div>
                          <Button onClick={()=>HandleAddRoc(element.UNICODE)} disabled={(element.color==="red" || element.color==="black")? false: true}>Rociar</Button>
                        </div>
                  }
                  {//Poner el boton de publicidad
                    user.USU_APP==="vectorpoint" && btnInspectionPasive && !element.colorBorde 
                      && 
                        <div className="wrapper-btn-publicity">
                          <Button className="btn-publicity" onClick={()=>HandlePublicity(element.UNICODE)}/>
                        </div>
                  }
                  {
                    element.additional === 0 &&
                    //Agregar una vivienda adicional
                    <AdditionalHousing valUnicode={element.UNICODE}/>
                  }
                </Popup>
              </Circle>
            : 
            <Marker 
              key = {element.UNICODE}
              position={[parseFloat(element.LATITUDE),parseFloat(element.LONGITUDE)]}
              icon = {customMarker}
            >
              {//Contorno amarillo en vivienda que tienen publicidad
                user.USU_APP!=="bluepoint" && btnInspectionPasive &&  element.colorBorde===COLOR_PUBLICIDAD
                  &&  <Circle
                        key = {element.UNICODE+"_1"}
                        center = {[parseFloat(element.LATITUDE),parseFloat(element.LONGITUDE)]}
                        fillColor = {element.colorBorde}
                        radius = {10}
                        color = "black"
                        weight = {0.2}
                        fillOpacity = {1}
                      />
              }
              {//Contorno azul en vivienda que tienen un agente
                btnInspectionPasive &&  element.colorBorde===COLOR_AGENTE
                  &&  <Circle
                        key = {element.UNICODE+"_2"}
                        center = {[parseFloat(element.LATITUDE),parseFloat(element.LONGITUDE)]}
                        fillColor = {element.colorBorde}
                        radius = {10}
                        color = "black"
                        weight = {0.2}
                        fillOpacity = {1}
                      />
              }
              {//Poner circulo azul
              user.USU_APP!=="bluepoint" && (btnInspectionPasive || btnInspectionActive) && 
                <Circle
                  key = {element.UNICODE}
                  center={[parseFloat(element.LATITUDE),parseFloat(element.LONGITUDE)]}
                  fillColor = "blue"
                  radius = {200}
                  color = "black"
                  weight = {0.2}
                  fillOpacity = {0.2}
                />
              }
              { //ROCIADO
                (btnSprayed || element.sprayed === COLOR_ROCIADO_POSITIVO || element.sprayed === COLOR_SEGUNDO_ROCIADO_POSITIVO)
                  &&  <Circle
                        key = {element.UNICODE}
                        center = {[parseFloat(element.LATITUDE),parseFloat(element.LONGITUDE)]}
                        fillColor = {element.sprayed}
                        radius = {7}
                        color = "transparent"
                        weight = {0.2}
                        fillOpacity = {1}
                      />
              }
              <Popup>
                  <b style={{fontSize:14}}>{element.UNICODE}</b>
                  <hr style={{margin:5}}/>
                  {element.inspectionText}
                  <hr style={{margin:3}}/>
                  {//Rociado
                    element.rociadoText
                  }
                  {//Boton Inspeccion 
                    <div>
                      <Button className="custom-button" onClick={()=>HandleAdd(element.UNICODE, element.color, element.initialColor, element.codeLocBlock)}>Inspeccionar</Button>
                    </div>
                  }
                  {//Rociado
                    btnSprayed
                     &&
                        <div>
                          <Button onClick={()=>HandleAddRoc(element.UNICODE)} disabled={(element.color==="red" || element.color==="black")? false: true}>Rociar</Button>
                        </div>
                  }
                  {//Poner el boton de publicidad
                    user.USU_APP!=="bluepoint" && btnInspectionPasive && !element.colorBorde 
                      && 
                        <div className="wrapper-btn-publicity">
                          <Button className="btn-publicity" onClick={()=>HandlePublicity(element.UNICODE)}/>
                        </div>
                  }
                  {
                    element.additional === 0 &&
                    //Agregar una vivienda adicional
                    <AdditionalHousing valUnicode={element.UNICODE}/>
                  }
              </Popup>
            </Marker>
        ))}

        { btnSprayed && !boolRociado
          &&
            <FormInsp modal={modal} ChangeModal={ChangeModal} formTitle={formTitle} unicode={unicode} riskColor={riskColor} initialColor={initialColor} codeLocBlock={codeLocBlock} startTime={startTime}/>
        }
        { btnSprayed && boolRociado &&
          <FormRociado modal={modal} ChangeModal={ChangeModal} formTitle={formTitle} unicode={unicode} />
        }
        { btnSprayed === true && boolProgramRoc
          &&
            <FormProgramRoc modal={modal} ChangeModal={ChangeModal} formTitle={formTitle} unicode={unicode}/>
        }
        { boolPublicity?
            <FormPublicityAgent modal={modal} ChangeModal={ChangeModal} formTitle={formTitle} unicode={unicode}/>
          : (btnInspectionPasive || btnInspectionActive)
            &&
              <FormInsp modal={modal} ChangeModal={ChangeModal} formTitle={formTitle} unicode={unicode} riskColor={riskColor} initialColor={initialColor} codeLocBlock={codeLocBlock} startTime={startTime}/>
        }
      </>
    );
}

export default CircleHouses;