import React, { useContext } from "react";

import Login from '../../components/Login';
import Activities from '../../components/Activities';
import Denunciations from '../../components/Activities/Denunciations';
import MyMap from '../../components/MyMap';
import AuthenticationContext from "../../context/authentication/AuthenticationContext";

const Home = () => {

    const AuthenticationsContext = useContext(AuthenticationContext);
    const { authenticated, btnDenunciation, btnInspectionPasive, btnInspectionActive, btnSprayed } = AuthenticationsContext;

    return(
        <>
        { !authenticated ? 
                <Login/> 
            : 
                !btnDenunciation && !btnInspectionPasive && !btnInspectionActive && !btnSprayed && <Activities/>
        }
        { authenticated && btnDenunciation && <Denunciations/>}
        { authenticated && (btnInspectionPasive || btnInspectionActive || btnSprayed) && <MyMap/>}
        </>
    );
}

export default Home;