import React, { useReducer, useContext } from 'react';
import ClienteAxios from "../../config/axios";
import Swal from "sweetalert2";

import InspectionContext from './InspectionContext';
import InspectionReducer from './InspectionReducer';
import {
    ADD_INSPECTION,
    ADD_INSPECTION_OFFLINE,
    CLEAN_INSPECTIONS_OFFLINE,
    UPDATE_INSPECTIONS
} from '../../types';
import SpinnerContext from "../spinner/SpinnerContext";
import { PutNA } from '../../resources';

const InspectionState = props => {
    
    const initialState = {
        inspections: [],
        newHealthPosts: [],
        inspectionsOffline: []
    }

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(InspectionReducer, initialState);
    //Cambiar estado de spinner
    const SpinnersContext = useContext(SpinnerContext);
    const { HideSpinner } = SpinnersContext;

    //FUNCIONES
    //* Obtener inspecciones por MICRORED
    const GetInspectionsMicrored = async ( microred ) => {
                
        try {
            let resultado = await ClienteAxios.post(`/api/inspecciones/${microred}`);

            return resultado;
            
        } catch (error) {
            console.log(error);
        }
    }

    //* Obtener inspecciones
    const GetInspections = async (newHouses, numMicrored) => {
        
      try {
        let resultado = await ClienteAxios.post(`/api/inspecciones/microred/${numMicrored}`);
        let data = resultado.data;
        let newData = [];
        //Solo quedan las inspecciones que pertenecen a este catchment-area
        if (data.length > 0 ) {
          data.forEach(element => {   
            newHouses.some(house => {
              if (house.UNICODE === element.UNICODE_BASE) {
                  newData.push(element);
                  return true;
              } else 
                  return false;
            });
          });
        }
        
        await HideSpinner();

        return newData;
          
      } catch (error) {
        //Alerta
        Swal.fire({
            title: 'Error',
            text: 'Error al llamar a inspecciones de la base de datos: '+error?.message
        });
        console.log(error);
      }
    }

    //Obtener inspecciones de hoy
    const GetInspectionsToday = async ( usuario ) => {
   
        try {
            let resultado = await ClienteAxios.post(`/api/inspecciones/user/${usuario}` );
            
            return resultado;
            
        } catch (error) {
            console.log(error);
        }
    }

    //* Agregar inspecciones
    const AddInspection = async ( inspection ) => {

      try {
        let data={};
        let option="";  
        //if ( navigator.onLine ) {
            const resultado = await ClienteAxios.post('/api/inspecciones', inspection );
            
            data = {...resultado.data.newData};
            option = resultado.data.option;
        /*} else {
            dispatch({
                type: ADD_INSPECTION_OFFLINE,
                payload: inspection
            });
            //Alistar resultado para mostrar en mapa
            const newData = {
                UNICODE:inspection.unicode,
                FECHA:inspection.fecha,
                STATUS_INSPECCION:inspection.status_inspeccion,
                INSP_HABITANTE_TELEFONO:inspection.insp_habitante_telefono,
                INTRA_CHIRIS:inspection.intra_chiris? "1":"0",
                PERI_CHIRIS:inspection.peri_chiris? "1":"0"
            }
            data = {...newData};
        }*/

        //Si todo sale bien en el backend
        if (option==="success") {
          dispatch({
            type: ADD_INSPECTION,
            payload: data
          });
          //Alerta
          Swal.fire({
              title: 'Correcto',
              text: 'Se registro correctamente la inspección',
              icon: 'success'
          })
          
        } else {
          Swal.fire({
            icon: "error",
            title: 'Error en backend',
            text: 'Hubo un error informar a los encargados de datos.'
          })
        }
          
      } catch (error) {
        //Aqui viene tambien cuando no hay internet
        console.log(error);
        Swal.fire({
            icon: "error",
            title: 'Hubo un error',
            text: 'Hay un problema con la conectividad, por favor ingrese la vivienda nuevamente'
        })
      }
    }

    //Funcion para terminar de acomodar todos los campos de INSPECCIONES
    const RecordInspection = ( currentInspection ) => {
        //*Haciendo verificaciones de campos
        //Verificaciones en el campo "caract_predio"
        if (currentInspection.status_inspeccion !== "LP" )
            currentInspection.tipo_lp = "NA";
        if (currentInspection.caract_predio === "LV")
            currentInspection.status_inspeccion = "NA";
        //Verificaciones en el campo "status_inspeccion"
        if ( currentInspection.status_inspeccion === "NA" || currentInspection.status_inspeccion === "C" 
            || currentInspection.status_inspeccion === "R" || currentInspection.status_inspeccion === "V"
            || currentInspection.status_inspeccion === "inspeccion" ) {

            currentInspection.entrevista = "NA";//A
        }
        if ( currentInspection.status_inspeccion === "NA" || currentInspection.status_inspeccion === "C" 
            || currentInspection.status_inspeccion === "R" || currentInspection.status_inspeccion === "entrevista"
            || currentInspection.status_inspeccion === "inspeccion" ) {
                
            currentInspection.motivo_volver = "NA";//B
            currentInspection.fecha_volver = "NA";//B
        }
        if ( currentInspection.status_inspeccion === "NA" || currentInspection.status_inspeccion === "C" 
            || currentInspection.status_inspeccion === "V" || currentInspection.status_inspeccion === "entrevista"
            || currentInspection.status_inspeccion === "inspeccion" ) {
                
            currentInspection.renuente = "NA";//C
            currentInspection.renuente_otro = "NA";//C
        }
        if ( currentInspection.status_inspeccion === "NA" || currentInspection.status_inspeccion === "C" 
            || currentInspection.status_inspeccion === "V" || currentInspection.status_inspeccion === "entrevista"
            || currentInspection.status_inspeccion === "entrevista" ) {
            
            currentInspection.intra_inspeccion = "NA";//D
            currentInspection.intra_chiris = "NA";
            currentInspection.intra_rastros = "NA";
            currentInspection.peri_inspeccion = "NA";
            currentInspection.peri_chiris = "NA";
            currentInspection.peri_rastros = "NA";
            currentInspection.personas_predio = "NA";
            currentInspection.perros = "NA";
            currentInspection.cant_perros = "NA";
            currentInspection.gatos = "NA";
            currentInspection.cant_gatos = "NA";
            currentInspection.aves_corral = "NA";
            currentInspection.cant_aves_corral = "NA";
            currentInspection.cuyes = "NA";
            currentInspection.cant_cuyes = "NA";
            currentInspection.conejos = "NA";
            currentInspection.cant_conejos = "NA";
            currentInspection.otros = "NA";
            currentInspection.text_otros = "NA";
            currentInspection.cant_otros = "NA";
        }

        //Cambiando vacios por NA
        PutNA(currentInspection);
        //Almacenando UNICODE_BASE
        currentInspection.unicode_base = currentInspection.unicode;
    }

    const CleanInspectionsOffline = () => {
        dispatch({
            type: CLEAN_INSPECTIONS_OFFLINE
        });
    }

    const UpdateInspections = (newInspections) => {
        dispatch({
            type: UPDATE_INSPECTIONS,
            payload: newInspections
        });
    }

    return(
        <InspectionContext.Provider
            value={{
                inspections: state.inspections,
                inspectionsOffline: state.inspectionsOffline,
                newHealthPosts: state.newHealthPosts,
                GetInspectionsMicrored,
                GetInspections,
                GetInspectionsToday,
                AddInspection,
                RecordInspection,
                CleanInspectionsOffline,
                UpdateInspections,
            }}
        >
            {props.children}
        </InspectionContext.Provider>
    );
}

export default InspectionState;