import React, {useContext} from "react";
import { Button } from 'react-bootstrap';

import SpinnerContext from '../../../context/spinner/SpinnerContext';
import AuthenticationContext from "../../../context/authentication/AuthenticationContext";
import DenunciationContext from "../../../context/denunciation/DenunciationContext";

const Synchronize = () => {
    
    //Spinner
    const SpinnersContext = useContext(SpinnerContext);
    const { ShowSpinner, HideSpinner } = SpinnersContext;
    //Datos de usuario
    const AuthenticationsContext = useContext(AuthenticationContext);
    const { user } = AuthenticationsContext;
    //Obtener el denuncias
    const DenunciationsContext = useContext(DenunciationContext);
    const { GetDenunciationsMicrored } = DenunciationsContext;

    const HandleSync = () => {
        ShowSpinner();
        GetDenunciationsMicrored(user.USU_MICRORED).then(()=>{
            HideSpinner();
        });
    }

    return (
        <Button className='sync' onClick={HandleSync}></Button>
    );
}

export default Synchronize;