import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { DateFull, Merge } from "../../resources";
import Spinner from '../Spinner';
import SpinnerContext from '../../context/spinner/SpinnerContext';
import AuthenticationContext from "../../context/authentication/AuthenticationContext";
import CsvContext from "../../context/csv/CsvContext";
import DenunciationContext from "../../context/denunciation/DenunciationContext";
import InspectionContext from '../../context/inspection/InspectionContext';
import RociadoContext from '../../context/rociados/RociadoContext';

const ExportCSV = ({cssId, btnName, option, fileName}) => {
    
    //Spinner
    const SpinnersContext = useContext(SpinnerContext);
    const { spinner, ShowSpinner, HideSpinner } = SpinnersContext;
    //datos del usuario
    const AuthenticationsContext = useContext(AuthenticationContext);
    const { user } = AuthenticationsContext;
    //Obtener viviendas
    const CsvsContext = useContext(CsvContext);
    const { houses } = CsvsContext; 
    //Obtener el state de Alerta
    const DenunciationsContext = useContext(DenunciationContext);
    const { GetDenunciationsMicrored } = DenunciationsContext;
    //Extraer los valores del context
    const InspectionsContext = useContext(InspectionContext);
    const { GetInspectionsMicrored } = InspectionsContext;
    //Extraer los rociados registrados del context
    const RociadosContext = useContext(RociadoContext);
    const { rociados } = RociadosContext;

    
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        ShowSpinner();
        //Solo los registros que pertenecen a la microred
        csvData = csvData.filter(element=> element.USU_MICRORED===user.USU_MICRORED);
        
        //Agregando fecha al nombre
        let currentDate = DateFull(new Date());
        fileName = fileName+'_'+currentDate;
        
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        
        FileSaver.saveAs(data, fileName + fileExtension);
        
        setTimeout(() => {
            HideSpinner();
        }, 1000);
    }

    const HandleDownload = ( option ) => {
        if (option === "btnDenunciations") {
            GetDenunciationsMicrored(user.USU_MICRORED).then((result)=>{
                exportToCSV(result.data,fileName);
            });
        } else if ( option === "btnRociados") {
            exportToCSV(rociados, fileName);
        } else if ( option === "btnInspections") {
            GetInspectionsMicrored(user.USU_MICRORED).then((result)=>{
                let data = result.data;

                //Solo si no es "admin" ingresa al IF
                if ( user.USU_PRIVILEGIOS !== "ADMIN"){
                    data.orderByProperty("HORA_INICIO", "DES");//Ordenandolo de forma descendente
                    data = data.uniqueByProperty("UNICODE");//Obtiene la primera ocurrencia duplicada
                    data.orderByProperty("HORA_INICIO");//Ordenamos de forma ascendente, como esta a un inicio
                    /*************************
                     * Agregar LONGITUD y LATITUD
                     * ***************************/
                    let housesLatLng = [];
                    houses.forEach(element => {
                        let { LATITUDE, LONGITUDE, UNICODE } = element;
                        housesLatLng.push({ LATITUDE, LONGITUDE, UNICODE });
                    });

                    data = Merge(data, housesLatLng, "UNICODE");
                    //Poner en la columna STATUS_INSPECCION si es DES, LV, LP
                    data.forEach(element => {
                      if (element.CARACT_PREDIO === "DES" || element.CARACT_PREDIO === "LP" || element.CARACT_PREDIO === "LV") {
                        element.STATUS_INSPECCION = element.CARACT_PREDIO;
                      }
                    });

                    data.forEach(element => {
                        delete element.INSP_HABITANTE_TELEFONO;
                        delete element.HORA_INICIO;
                        delete element.HORA_FIN;
                        delete element.INITIAL_COLOR;
                        delete element.RISK_COLOR;
                        delete element.LAT;
                        delete element.LNG;
                    });
                }
                exportToCSV(data,fileName);
            });
        }
    }

    return (
        <>
            { spinner ? (<Spinner/>) : null }
            <Button id={cssId} onClick={(e) => HandleDownload(option)}>{btnName}</Button>
        </>
    )
}

export default ExportCSV;