import {
    BTN_COLINDANTE,
    DISABLED_BTN_COLINDANTE,
    BTN_ADD_ROCIADO,
    DISABLED_BTN_ADD_ROCIADO,
    ADD_ROCIADO,
    GET_ROCIADOS,
    BTN_NO_COLINDANTE,
    DISABLED_BTN_NO_COLINDANTE,
    BTN_NO_ROCIADO,
    DISABLED_BTN_NO_ROCIADO,
    ADD_SPRAY_OFFLINE,
    CLEAN_SPRAYS_OFFLINE
}from '../../types';

export default (state, action) => {
    switch(action.type) {

        case BTN_ADD_ROCIADO:
            return{
                ...state,
                statusBtnAddRoc:true
            };

        case DISABLED_BTN_ADD_ROCIADO:
            return{
                ...state,
                statusBtnAddRoc:false
            };
        
        case BTN_NO_ROCIADO:
            return{
                ...state,
                statusBtnNoRoc: true
            };

        case DISABLED_BTN_NO_ROCIADO:
            return{
                ...state,
                statusBtnNoRoc: false
            };

        case BTN_COLINDANTE:
            return{
                ...state,
                colindante: true
            };

        case DISABLED_BTN_COLINDANTE:
            return{
                ...state,
                colindante: false
            };

        case BTN_NO_COLINDANTE:
            return{
                ...state,
                noColindante: true
            };
            
        case DISABLED_BTN_NO_COLINDANTE:
            return{
                ...state,
                noColindante: false
            };
        
        case ADD_ROCIADO:
            return{
                ...state,
                rociados: [...state.rociados, action.payload]
            };

        case ADD_SPRAY_OFFLINE:
            return{
                ...state,
                spraysOffline: [...state.spraysOffline, {...action.payload}]
            };
        case GET_ROCIADOS:
            return{
                ...state,
                rociados: action.payload
            };
        case CLEAN_SPRAYS_OFFLINE:
            return{
                ...state,
                spraysOffline: []
            };
        default:
            return state;
    }
}