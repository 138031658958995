import Swal from "sweetalert2";

//Mensaje sobre la cantidad de dias trabajados
const MessageDaysWorked = (dateInitialWorked, totalDaysWorked, newInspections) => {
    
    let dates = [];//Se almacenara todas las fechas unicas
    newInspections.forEach(element => {
      //Solo las inspecciones que son de la fecha de trabajo incial dada en adelante
      if (element.FECHA >= dateInitialWorked) {
        if (dates.indexOf(element.FECHA) === -1) {
          dates.push(element.FECHA);
        } 
      }
    });

    let remainingDays = parseInt(totalDaysWorked) - dates.length;

    return(
      Swal.fire({
        title: 'Tiene aún '+remainingDays+' días de trabajo en la zona',
        showClass: {
            popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
        }
      })
    );
}

export default MessageDaysWorked;