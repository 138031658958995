import React, { useReducer } from 'react';
import ClienteAxios from "../../config/axios";

import PublicityContext from './PublicityContext';
import PublicityReducer from './PublicityReducer';
import { 
    GET_PUBLICITIES,
    ADD_PUBLICITY_OFFLINE,
    CLEAN_PUBLICITIES_OFFLINE
} from '../../types';

const PublicityState = props => {
    
    const initialState = {
        publicities: [],
        publicitiesOffline: []
    }

    //Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(PublicityReducer, initialState);

    //FUNCIONES
    //* Obtener publicidades por MICRORED
    const GetPublicitiesMicrored = async ( microred ) => {
          
        try {
            let resultado = await ClienteAxios.post(`/api/publicidades/${microred}`);
            
            if (resultado.data.length > 0) {
                dispatch({
                    type: GET_PUBLICITIES,
                    payload: resultado.data
                });

                return resultado.data;
            } else {
                return 0;
            }
            
        } catch (error) {
            console.log(error);
        }
    }

    //* Agregar publicidad
    const AddPublicity = async ( publicity ) => {
        
        try {
            if ( navigator.onLine ) {
                let result = await ClienteAxios.post('/api/publicidades', publicity );
            
                if (result.data.option === 'error') {
                    console.log(result.data.msg);
                }  
            } else {
                dispatch({
                    type: ADD_PUBLICITY_OFFLINE,
                    payload: publicity
                });
            }

            return true;
        } catch (error) {
            console.log(error);
        }
    }

    const CleanPublicitiesOffline = () => {
        dispatch({
            type: CLEAN_PUBLICITIES_OFFLINE
        });
    }

    return(
        <PublicityContext.Provider
            value={{
                publicities: state.publicities,
                publicitiesOffline: state.publicitiesOffline,
                GetPublicitiesMicrored,
                AddPublicity,
                CleanPublicitiesOffline
            }}
        >
            {props.children}
        </PublicityContext.Provider>
    );
}

export default PublicityState;