import React, {useContext} from "react";

import AuthenticationContext from "../../context/authentication/AuthenticationContext";

const Score = () => {
    const AuthenticationsContext = useContext(AuthenticationContext);
    const { user } = AuthenticationsContext;
    
    let result = null
    if( user.USU_APP !== "no-app"){
        result = <div className="score">{ user.SCORE }</div>
    }
    return(result);
}

export default Score;