import { 
    ADD_CIMEX
} from '../../types';

export default (state, action) => {
    switch (action.type) {
        case ADD_CIMEX:
            return{
                ...state,
                cimex: [...state.cimex, action.payload]
            };
        default:
            return state;
    }
}